import { AnyAction, createAction, createReducer } from "@reduxjs/toolkit";
import { UsersReducer } from "./type.usuarios";

export const USER_CREATE_PAYLOAD = createAction<{ [key: string]: any }>(
  "USER_CREATE_PAYLOAD"
);
export const USER_CREATE_SUCCESS = createAction<{ msg: string }>(
  "USER_CREATE_SUCCESS"
);
export const USER_CREATE_ERROR =
  createAction<{ msg: string }>("USER_CREATE_ERROR");
export const USER_ASYNC_ALL = createAction("USER_ASYNC_ALL");
export const USER_CHANGED_EVENTS = createAction<{ [key: string]: any }>(
  "USER_CHANGED_EVENTS"
);
export const USER_LOADING_SUCCESS = createAction<{
  data: Array<Partial<UsersReducer>>;
}>("USER_LOADING_SUCCESS");
export const USER_SET_TO_STORE =
  createAction<{ [key: string]: any }>("USER_SET_TO_STORE");
export const USER_CHANGED_STATE = createAction<any>("USER_CHANGED_STATE");
export const USER_DELETE_ID = createAction<{ id: any }>(
  "USER_DELETE_ID"
);
const initalState: {
  loading: boolean;
  users: Array<Partial<UsersReducer>>;
  success: boolean | null;
  error: boolean | null;
  msg: string | null;
  count: number;
  page: number;
  pageSize: number;
  search: string;
  user: Partial<UsersReducer>;
} = {
  loading: false,
  users: [],
  success: null,
  error: null,
  msg: null,
  count: 0,
  page: 0,
  pageSize: 6,
  search: "",
  user: {},
};
const userReducer = createReducer(initalState, (build) => {
  build
    .addCase(USER_CHANGED_EVENTS.type, (state, action: AnyAction) => {
      return {
        ...state,
        ...action.payload,
      };
    })
    .addCase(USER_LOADING_SUCCESS.type, (state, action: AnyAction) => {
      return {
        ...state,
        users: action.payload.data,
        count: action.payload.count,
      };
    })
    .addCase(USER_CREATE_SUCCESS.type, (state, action: AnyAction) => {
      state.error = false;
      state.success = true;
      state.msg = action.payload;
    })
    .addCase(USER_CREATE_ERROR.type, (state, action: AnyAction) => {
      state.error = false;
      state.success = false;
      state.msg = action.payload;
    })
    .addCase(USER_CHANGED_STATE.type, (state, action: AnyAction) => {
      // eslint-disable-next-line no-param-reassign
      return {
        ...state,
        ...action.payload,
      };
    });
});

export default userReducer;
