import { Instagram, NotificationAdd } from "@mui/icons-material";
import {
  Box,
  Button,
  Paper,
  Stack,
  TextField,
  Typography,
  Slide,
  Backdrop,
  CircularProgress,
  Alert,
} from "@mui/material";
import { green } from "@mui/material/colors";
import { styled } from "@mui/system";
import { useAppDispatch, useAppSelector } from "hooks/storeHook";
import React from "react";
import { useForm } from "react-hook-form";
import Snackbar from "@mui/material/Snackbar";
import { SOCIALMIDIA_ASYNC_ALL, SOCIALMIDIA_CREATE_PAYLOAD } from "store/reducers/socialmidias/socialmidias.reducer";
import { useNavigate, useParams } from "react-router-dom";

const SocialMidiasEditPage: React.FC = () => {
  const [animationState, setAnimationState] = React.useState(false);
  const dispatchAction = useAppDispatch();
  const Navigation = useNavigate();
  const state = useAppSelector((state) => state.socialmidias);
  const { register, handleSubmit, reset, setValue } = useForm({
    //defaultValues: state.socialMidia,
  });

  React.useEffect(() => {
    dispatchAction(SOCIALMIDIA_ASYNC_ALL());
    setAnimationState(true);
    return () => setAnimationState(false);
  }, []);

  React.useEffect(() => {
    console.log(state.socialMidia);
    if (state.socialMidia && state.socialMidia !== null) {
      setValue('id', state.socialMidia.id);
      setValue('instaRamoEstendido', state.socialMidia.instaRamoEstendido);
      setValue('instaNovaSafra', state.socialMidia.instaNovaSafra);
      setValue('instaVigilia', state.socialMidia.instaVigilia);
    }
  }, [state.socialMidia]);

  const Submit = (data: any) => {
    //console.log(data);
    dispatchAction(SOCIALMIDIA_CREATE_PAYLOAD({ ...data }));
    //reset();
  };

  return (
    <Slide direction="up" in={animationState} mountOnEnter unmountOnExit>
      <Box
        sx={{
          width: "95%",
          margin: "0 auto",
          height: "100vh",
          mt: { xs: 5, sm: 0 },
        }}
      >
        <Box
          component={Paper}
          py={5}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            flexBasis: 1,
            alignItems: "center",
            width: "80%",
            margin: "0 auto",
          }}
        >
          <Box
            py={2}
            sx={{ display: "flex", flexDirection: "column" }}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Instagram sx={{ fontSize: "3.5em", color: green[500] }} />
            <Typography variant="h5">
              Mídias Social
            </Typography>
          </Box>
          <Stack
            component={"form"}
            noValidate
            direction={"column"}
            spacing={2}
            sx={{ width: "80%" }}
            onSubmit={handleSubmit(Submit)}
          >
            <TextField
              type="text"
              label="Instagram Ramo Estendido"
              fullWidth
              {...register("instaRamoEstendido")}
            />
            <TextField
              type="text"
              label="Instagram Nova Safra"
              fullWidth
              {...register("instaNovaSafra")}
            />
            <TextField
              type="text"
              label="Instagram Vigilia Web"
              fullWidth
              {...register("instaVigilia")}
            />
            <Button color="primary" type="submit" variant="contained" fullWidth>
              Salvar
            </Button>
          </Stack>
        </Box>
        <Backdrop
          sx={{
            bgColor: "#3333",
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={state.loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Snackbar
          open={state.success || false}
          autoHideDuration={2000}
        //onClose={() => Navigation("/notifications/list")}
        >
          <Alert severity="success" sx={{ width: "100%" }}>
            Mídias alteradas com sucesso
          </Alert>
        </Snackbar>
        <Snackbar
          open={state.error || false}
          autoHideDuration={6000}
        >
          <Alert severity="error" sx={{ width: "100%" }}>
            {state.msg}
          </Alert>
        </Snackbar>
      </Box>
    </Slide>
  );
};

export default SocialMidiasEditPage;
