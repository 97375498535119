import {
  Backdrop,
  Button,
  CircularProgress,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Stack,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useAppDispatch, useAppSelector } from "hooks/storeHook";
import { useNavigate } from "react-router-dom";
import { TRAINING_EXCEL } from "store/reducers/trainings/trainings.reducer";

const TrainingRegistrationsPage: React.FC = () => {
  const trainingsState = useAppSelector((state) => state.trainings);
  const dispatch = useAppDispatch();
  const Navigation = useNavigate();

  const [respostasModal, setRespostasModal] = React.useState({
    index: -1,
    opened: false,
  });
  const handleClickOpen = (index: number) => {
    setRespostasModal({
      index: index,
      opened: true,
    });
  };
  const handleClose = () => {
    setRespostasModal({
      index: -1,
      opened: false,
    });
  };

  const columns: GridColDef[] = [
    {
      field: "user",
      headerName: "Usuário",
      width: 300,
      valueGetter: (params) => {
        //console.log({ params });
        return params.row.user.name;
      },
    },
    {
      field: "insertedAt",
      headerName: "Criado em",
      width: 140,
      renderCell: (params: GridRenderCellParams<Date>) => {
        const datatime = new Date(params.value);
        return (
          <div>
            {`${datatime.getDate().toString().padStart(2, "0")}/${(
              datatime.getMonth() + 1
            )
              .toString()
              .padStart(2, "0")}/${datatime.getFullYear()} ${datatime
                .getHours()
                .toString()
                .padStart(2, "0")}:${datatime
                  .getMinutes()
                  .toString()
                  .padStart(2, "0")}`}
          </div>
        );
      },
    },
    {
      field: "id",
      headerName: "Ações",
      width: 270,
      renderCell: (params: GridRenderCellParams<number>) => (
        <>
          <Button
            variant="contained"
            color="info"
            size="small"
            style={{ marginLeft: 16 }}
            onClick={() => {
              trainingsState.training.registrations.map(
                (item: any, index: number) => {
                  if (item.id === Number(params.value)) {
                    handleClickOpen(index);
                  }
                }
              );
            }}
          >
            Visualizar Respostas
          </Button>
        </>
      ),
    },
  ];

  return (
    <Stack
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "85vh",
        background: (theme) => theme.palette.background.default,
      }}
      spacing={2}
    >
      <Typography variant="h5">{trainingsState.training.name}</Typography>
      <Box sx={{ width: "80%", margin: " 0 auto " }}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => {
            dispatch(TRAINING_EXCEL({ id: trainingsState.training.id }));
            //window.open(`http://localhost:3000/api/training/${trainingsState.training.id}/excel`);
          }}
        >
          DOWNLOAD EXCEL
        </Button>
      </Box>
      <Box sx={{ width: "90%", height: "95%", margin: "0 auto" }}>
        <DataGrid
          rows={trainingsState.training.registrations}
          columns={columns}
          pagination
          paginationMode="client"
        />
      </Box>
      {trainingsState.loading && (
        <Backdrop
          sx={{
            bgColor: "#3333",
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={trainingsState.loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Dialog
        open={respostasModal.opened}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {respostasModal.index >= 0 &&
            trainingsState.training.registrations[respostasModal.index].user
              .name}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Respostas
          </DialogContentText>
          <DialogContent>
            {respostasModal.index >= 0 &&
              trainingsState.training.registrations[
                respostasModal.index
              ].answers.map((answer: any) => {
                return (
                  <DialogContentText>
                    {answer.question.name}: <strong>{answer.value}</strong>
                  </DialogContentText>
                );
              })}
          </DialogContent>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
};

export default TrainingRegistrationsPage;
