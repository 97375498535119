/* eslint-disable @typescript-eslint/no-unused-expressions */
import { API_METHODS } from "services/api";
import {
  EVENT_ASYNC_ALL,
  EVENT_CREATE_PAYLOAD,
  EVENT_CHANGED_EVENTS,
  EVENT_LOADING_SUCCESS,
  EVENT_CREATE_SUCCESS,
  EVENT_CREATE_ERROR,
  EVENT_DELETE_ID,
} from "store/reducers/events/events.reducer";
import { takeLatest, fork, take, select, call, put, cancel } from "typed-redux-saga";

function* getEvents() {
  try {
    yield put(
      EVENT_CHANGED_EVENTS({
        loading: true,
        success: false,
        error: false,
      })
    );
    const token = yield* select((state) => state.auth.auth.token);
    const events = yield* select((state) => state.events);
    const Request = yield* call(API_METHODS.GET_ALL_EVENTS, token, events.page, events.pageSize, events.search);
    yield put(EVENT_LOADING_SUCCESS(Request?.data));
    return Request;
  } catch (error) {
    yield put(
      EVENT_CHANGED_EVENTS({
        loading: false,
        success: false,
        error: true,
      })
    );
    return error;
  } finally {
    yield put(
      EVENT_CHANGED_EVENTS({
        loading: false,
      })
    );
  }
}
function* getAllEventsFoork() {
  yield* call(getEvents);
}
function* createEventSaga(action: any) {
  //console.log("createEventSaga");
  const token = yield* select((state) => state.auth.auth.token);
  const { id, image, imageUrl, ...normalize } = action.payload;

  if (normalize.dateTime !== undefined) {
    //console.log(normalize.dateTime);
    normalize.dateTime += ` 00:01:00`;
  }

  //console.log(normalize);
  try {
    var result;
    yield* put(EVENT_CHANGED_EVENTS({ loading: true, success: false, error: false }));
    if (id !== undefined && id > 0) {
      result = yield* call(API_METHODS.UPDATE_A_EVENTS, token, id, normalize);
    } else {
      result = yield* call(API_METHODS.CREATE_A_EVENTS, token, normalize);
    }
    if (result.data.id) {
      //console.log(result.data);
      if (image !== undefined && image.length > 0) {
        //console.log(image);
        const imageUpload = new FormData();
        Array.from(image).map((img: any) => {
          imageUpload.append("image", img);
        });
        const imageIsUploaded = yield* call(
          API_METHODS.UPLOAD_A_EVENTS,
          token,
          result.data.id,
          imageUpload
        );
        //console.log(imageIsUploaded);
      }

      yield* put(EVENT_CHANGED_EVENTS({ loading: false, success: true, error: false }));
    }
  } catch (error) {
    yield* put(
      EVENT_CHANGED_EVENTS({
        loading: false,
        error: true,
        msgError: "Ocorreu um erro a cadastrar evento",
      })
    );
  } finally {
    yield* put(EVENT_CHANGED_EVENTS({ loading: false }));
  }
}
function* asyncDeleteEvent(action: any) {
  const { payload } = action;
  yield put(
    EVENT_CHANGED_EVENTS({
      loading: true,
      success: false,
    })
  );
  //console.log(`asyncDeleteEvent`);
  //console.log(payload);
  const { id } = payload;
  const token = yield* select((state) => state.auth.auth.token);
  try {
    let Request;
    if ((id) && (id !== 0)) {
      //console.log(id);
      Request = yield* call(API_METHODS.DELETE_A_EVENTS, token, id);
    }
    //console.log(Request);
    yield put(EVENT_CREATE_SUCCESS({ msg: "Evento deletado corretamente" }));
    yield put(
      EVENT_CHANGED_EVENTS({
        loading: false,
        success: true,
        error: false,
      })
    );
    return Request;
  } catch (error) {
    yield* put(
      EVENT_CREATE_ERROR({ msg: "Ocorreu um erro ao deletar o evento" })
    );
    yield put(
      EVENT_CHANGED_EVENTS({
        loading: false,
        success: false,
        error: true,
      })
    );
  } finally {
    /*yield put(
      USER_CHANGED_STATE({
        loading: false,
      })
    );*/
    yield console.log("FINALLY");
  }
}
export default function* eventsSaga() {
  while (true) {
    yield* take([EVENT_ASYNC_ALL.type]);
    const asyncAll = yield* fork(getAllEventsFoork);
    if (!asyncAll) {
      yield* cancel(asyncAll);
    }
    yield* takeLatest(EVENT_CREATE_PAYLOAD.type, createEventSaga);
    yield* takeLatest(EVENT_DELETE_ID.type, asyncDeleteEvent);
  }
}
