import { configureStore } from "@reduxjs/toolkit";
import Logger from "redux-logger";
import createSagaMiddleware from "@redux-saga/core";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import reducers from "./reducers/reducers";
import rootSaga from "./sagas/rootSaga";

const sagaMiddlware = createSagaMiddleware();
const midleware = [Logger, sagaMiddlware];

const persistConfig = {
  key: "app",
  storage,
  whitelist: ["auth"],
  blacklist: ["feeds", "trainings", "users", "events", "notifications", "socialmidias"],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: midleware,
});
sagaMiddlware.run(rootSaga);
export const psersistedStore = persistStore(store);
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
