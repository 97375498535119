import { API_METHODS } from "services/api";
import {
  DASH_ASYNC,
  DASH_ASYNC_SUCCESS,
  DASH_ASYNC_ERROR,
  DASH_CHANGED_STATE,
} from "store/reducers/dashboard/dash.reducer";
import {
  take,
  cancel,
  call,
  select,
  put,
  delay,
  takeLatest,
  fork,
} from "typed-redux-saga";

function* asyncGetDashboard() {
  //console.log(`asyncGetDashboard`);
  try {
    yield put(
      DASH_CHANGED_STATE({
        loading: true,
        error: false,
        success: false,
        msg: "",
      })
    );
    const token = yield* select((state) => state.auth.auth.token);
    const listUsers = yield* call(API_METHODS.GET_ALL_DASHBOARD, token);
    yield put(DASH_ASYNC_SUCCESS(listUsers?.data));
    return listUsers;
  } catch (error) {
    DASH_ASYNC_ERROR({
      msg: "Erro ao tentar carregar usuários.",
    });
  } finally {
    yield put(
      DASH_CHANGED_STATE({
        loading: false,
      })
    );
  }
}
export default function* dashFlow() {
  while (true) {
    yield* take([DASH_ASYNC.type]);
    const asyncAllUsers = yield* fork(asyncGetDashboard);
    if (!asyncAllUsers) {
      yield* cancel(asyncAllUsers);
    }
  }
}
