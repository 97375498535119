import * as React from "react";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import { green } from "@mui/material/colors";
import {
  Dashboard,
  Person,
  Feed,
  School,
  Logout,
  Event as EventIcon,
} from "@mui/icons-material";
import { styled } from "@mui/system";
const BottomNavigationStyled = styled(BottomNavigation)((theme) => ({
  width: "100%",
  backgroundColor: `${green[500]}`,
}));
const BottomNavigationActionStyled = styled(BottomNavigationAction)(
  (theme) => ({
    ".MuiBottomNavigationAction-root": {
      color: "white",
    },
    ".MuiBottomNavigationAction-label": {
      color: "#fff",
    },
    ".Mui-selected": {
      color: "white",
    },
  })
);
export default function TopNavigation() {
  const [value, setValue] = React.useState();
  React.useEffect(() => {
    console.log(document.location.pathname);
  }, []);
  return (
    <BottomNavigationStyled
      value={value}
      onChange={(event, newValue) => {
        //console.log(newValue);
        setValue(newValue);
      }}
      sx={{
        position: "fixed",
        justifyContent: "space-between",
        padding: 0,
        margin: 0,
        display: { sm: "none", xs: "flex" },
      }}
    >
      <BottomNavigationActionStyled
        label="Dashboard"
        icon={<Dashboard sx={{ color: green["A100"] }} />}
      />
      <BottomNavigationActionStyled
        label="Usuarios"
        icon={<Person sx={{ color: green["A100"] }} />}
      />
      <BottomNavigationActionStyled
        label="Feeds"
        icon={<Feed sx={{ color: green["A100"] }} />}
      />
      <BottomNavigationActionStyled
        label="Treinamentos"
        icon={<School sx={{ color: green["A100"] }} />}
      />
      <BottomNavigationActionStyled
        label="Eventos"
        icon={<EventIcon sx={{ color: green["A100"] }} />}
      />
      <BottomNavigationActionStyled
        label="Sair"
        icon={<Logout sx={{ color: green["A100"] }} />}
      />
    </BottomNavigationStyled>
  );
}
