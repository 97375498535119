import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "./auth/auth.reducer";
import userReducer from "./usuarios/users.reducer";
import feedsReducer from "./feeds/feeds.reducer";
import dashReducer from "./dashboard/dash.reducer";
import eventsReducer from "./events/events.reducer";
import notificationsReducer from "./notifications/notifications.reducer";
import trainingsReducer from "./trainings/trainings.reducer";
import socialmidiasReducer from "./socialmidias/socialmidias.reducer";
export default combineReducers({
  users: userReducer,
  auth: authReducer,
  feeds: feedsReducer,
  dash: dashReducer,
  events: eventsReducer,
  notifications: notificationsReducer,
  trainings: trainingsReducer,
  socialmidias: socialmidiasReducer,
});
