import {
  Backdrop,
  Button,
  CircularProgress,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useAppDispatch, useAppSelector } from "hooks/storeHook";
import {
  USER_ASYNC_ALL,
  USER_DELETE_ID,
  USER_CHANGED_EVENTS,
} from "store/reducers/usuarios/users.reducer";
import { useNavigate } from "react-router-dom";

const UserPage: React.FC = () => {
  const usersState = useAppSelector((state) => state.users);
  const dispatch = useAppDispatch();
  const Navigation = useNavigate();

  const [deleteModal, setDeleteModal] = React.useState({ id: 0, open: false });
  const handleClickOpen = (id: number) => {
    setDeleteModal({
      open: true,
      id: id,
    });
  };
  const handleClose = () => {
    setDeleteModal({
      open: false,
      id: 0,
    });
  };
  const handleDeleteUser = () => {
    dispatch(USER_DELETE_ID({ id: deleteModal.id }));
    handleClose();
    dispatch(USER_ASYNC_ALL());
  };

  const handleChange = (e: { target: { value: string } }) => {
    //console.log(e.target.value);
    dispatch(USER_CHANGED_EVENTS({ page: 0, search: e.target.value }));
    dispatch(USER_ASYNC_ALL());
  };

  React.useEffect(() => {
    dispatch(USER_ASYNC_ALL());
  }, []);

  const columns: GridColDef[] = [
    {
      field: "active",
      headerName: "Ativo",
      width: 80,
      renderCell: (params: GridRenderCellParams<Boolean>) => (
        <strong>{`${params.value ? "Ativo" : "Inativo"}`}</strong>
      ),
    },
    { field: "name", headerName: "Nome", width: 200 },
    { field: "userName", headerName: "Usuário", width: 100 },
    { field: "email", headerName: "email", width: 200 },
    {
      field: "insertedAt",
      headerName: "Criado em",
      width: 150,
      renderCell: (params: GridRenderCellParams<Date>) => {
        const datatime = new Date(params.value);
        return (
          <div>
            {`${datatime.getDate().toString().padStart(2, "0")}/${(
              datatime.getMonth() + 1
            )
              .toString()
              .padStart(2, "0")}/${datatime.getFullYear()} ${datatime
                .getHours()
                .toString()
                .padStart(2, "0")}:${datatime
                  .getMinutes()
                  .toString()
                  .padStart(2, "0")}`}
          </div>
        );
      },
    },
    {
      field: "id",
      headerName: "Ações",
      width: 200,
      renderCell: (params: GridRenderCellParams<number>) => (
        <strong>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 16 }}
            onClick={() => {
              usersState.users.map((item) => {
                if (item.id === Number(params.value)) {
                  dispatch(
                    USER_CHANGED_EVENTS({
                      user: item,
                      success: false,
                      error: false,
                    })
                  );
                }
              });
              Navigation(`/operators/edit/${params.value}`);
            }}
          >
            Editar
          </Button>
          <Button
            variant="contained"
            color="error"
            size="small"
            style={{ marginLeft: 16 }}
            onClick={() => handleClickOpen(params.value)}
          >
            Excluir
          </Button>
        </strong>
      ),
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "85vh",
        background: (theme) => theme.palette.background.default,
      }}
    >
      <Typography variant="h5">Operadores</Typography>
      <Box sx={{ width: "90%", height: "70px", margin: "0 auto" }}>
        <TextField
          type="text"
          label="Pesquisar"
          fullWidth
          onChange={handleChange}
        />
      </Box>
      <Box sx={{ width: "90%", height: "95%", margin: "0 auto" }}>
        <DataGrid
          rows={usersState.users}
          columns={columns}
          pagination
          paginationMode="server"
          rowsPerPageOptions={[6, 10, 20]}
          pageSize={usersState.pageSize}
          page={usersState.page}
          rowCount={usersState.count}
          loading={usersState.loading}
          onPageChange={(_page) => {
            dispatch(USER_CHANGED_EVENTS({ page: _page }));
            dispatch(USER_ASYNC_ALL());
          }}
          onPageSizeChange={(_size) => {
            dispatch(USER_CHANGED_EVENTS({ pageSize: _size }));
            dispatch(USER_ASYNC_ALL());
          }}
        />
      </Box>
      {usersState.loading && (
        <Backdrop
          sx={{
            bgColor: "#3333",
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={usersState.loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Dialog
        open={deleteModal.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Deseja realmente excluir ?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Removerá o usuário
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleDeleteUser} autoFocus>
            Remover
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default UserPage;
