import { NotificationAdd } from "@mui/icons-material";
import {
  Box,
  Button,
  Paper,
  Stack,
  TextField,
  Typography,
  Slide,
  Backdrop,
  CircularProgress,
  Alert,
} from "@mui/material";
import { green } from "@mui/material/colors";
import { styled } from "@mui/system";
import { useAppDispatch, useAppSelector } from "hooks/storeHook";
import React from "react";
import { useForm } from "react-hook-form";
import Snackbar from "@mui/material/Snackbar";
import { NOTIFICATION_CREATE_PAYLOAD } from "store/reducers/notifications/notifications.reducer";
import { useNavigate } from "react-router-dom";

const Input = styled("input")({
  display: "none",
});

const NotificationNewPage: React.FC = () => {
  const [animationState, setAnimationState] = React.useState(false);
  const dispatchAction = useAppDispatch();
  const Navigation = useNavigate();
  const notificationsState = useAppSelector((state) => state.notifications);
  const { register, handleSubmit, reset, setValue, control } = useForm();

  React.useEffect(() => {
    setAnimationState(true);
    return () => setAnimationState(false);
  }, []);

  const Submit = (data: any) => {
    //console.log(data);
    dispatchAction(NOTIFICATION_CREATE_PAYLOAD({ ...data }));
    reset();
  };

  return (
    <Slide direction="up" in={animationState} mountOnEnter unmountOnExit>
      <Box
        sx={{
          width: "95%",
          margin: "0 auto",
          height: "100vh",
          mt: { xs: 5, sm: 0 },
        }}
      >
        <Box
          component={Paper}
          py={5}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            flexBasis: 1,
            alignItems: "center",
            width: "80%",
            margin: "0 auto",
          }}
        >
          <Box
            py={2}
            sx={{ display: "flex", flexDirection: "column" }}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <NotificationAdd sx={{ fontSize: "3.5em", color: green[500] }} />
            <Typography variant="h5">
              Envio de notificação para os usuários do app
            </Typography>
          </Box>
          <Stack
            component={"form"}
            noValidate
            direction={"column"}
            spacing={2}
            sx={{ width: "80%" }}
            onSubmit={handleSubmit(Submit)}
          >
            <TextField
              type="text"
              label="Título"
              fullWidth
              {...register("title")}
            />
            <TextField
              type="text"
              fullWidth
              multiline
              label={"Mensagem"}
              {...register("body")}
              rows={3}
            />
            <TextField
              type="datetime-local"
              {...register("scheduled")}
              fullWidth
            />
            <Stack
              direction={"row"}
              spacing={5}
              sx={{ width: "100%" }}
            >
              <Button color="primary" variant="contained" fullWidth onClick={() => {
                setValue("scheduled", null);
                //console.log(control._formValues);
                dispatchAction(NOTIFICATION_CREATE_PAYLOAD(control._formValues));
                reset();
              }}>
                Notificar
              </Button>
              <Button color="primary" type="submit" variant="contained" fullWidth>
                Programar
              </Button>
            </Stack>
          </Stack>
        </Box>
        <Backdrop
          sx={{
            bgColor: "#3333",
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={notificationsState.loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Snackbar
          open={notificationsState.success || false}
          autoHideDuration={2000}
          onClose={() => Navigation("/notifications/list")}
        >
          <Alert severity="success" sx={{ width: "100%" }}>
            Notificação cadastrada com sucesso
          </Alert>
        </Snackbar>
        <Snackbar
          open={notificationsState.error || false}
          autoHideDuration={6000}
        >
          <Alert severity="error" sx={{ width: "100%" }}>
            {notificationsState.msg}
          </Alert>
        </Snackbar>
      </Box>
    </Slide>
  );
};

export default NotificationNewPage;
