import { AnyAction, createAction, createReducer } from "@reduxjs/toolkit";

export const TRAINING_CREATE_PAYLOAD = createAction<{ [key: string]: any }>(
  "TRAINING_CREATE_PAYLOAD"
);
export const TRAINING_CREATE_SUCCESS = createAction<{ msg: string }>(
  "TRAINING_CREATE_SUCCESS"
);
export const TRAINING_CREATE_ERROR =
  createAction<{ msg: string }>("TRAINING_CREATE_ERROR");
export const TRAINING_ASYNC_ALL = createAction("TRAINING_ASYNC_ALL");
export const TRAINING_CHANGED_EVENTS = createAction<{ [key: string]: any }>(
  "TRAINING_CHANGED_EVENTS"
);
export const TRAINING_LOADING_SUCCESS = createAction<{
  data: Array<Partial<any>>;
}>("TRAINING_LOADING_SUCCESS");
export const TRAINING_DELETE_ID = createAction<{ id: any }>(
  "TRAINING_DELETE_ID"
);
export const TRAINING_EXCEL = createAction<{ id: any }>(
  "TRAINING_EXCEL"
);
const initalState: {
  id: number;
  loading: boolean;
  trainings: Array<Partial<any>>;
  success: boolean | null;
  error: boolean | null;
  msg: string | null;
  count: number;
  page: number;
  pageSize: number;
  search: string;
  training: Partial<any>;
} = {
  id: 0,
  loading: false,
  trainings: [],
  success: null,
  error: null,
  msg: null,
  count: 0,
  page: 0,
  pageSize: 6,
  search: "",
  training: {},
};
const trainingsReducer = createReducer(initalState, (build) => {
  build
    .addCase(TRAINING_CHANGED_EVENTS.type, (state, action: AnyAction) => {
      return {
        ...state,
        ...action.payload,
      };
    })
    .addCase(TRAINING_LOADING_SUCCESS.type, (state, action: AnyAction) => {
      return {
        ...state,
        trainings: action.payload.data,
        count: action.payload.count,
      };
    })
    .addCase(TRAINING_CREATE_SUCCESS.type, (state, action: AnyAction) => {
      state.error = false;
      state.success = true;
      state.trainings = action.payload;
    })
    .addCase(TRAINING_CREATE_ERROR.type, (state, action: AnyAction) => {
      state.error = false;
      state.success = false;
      state.msg = action.payload;
    });
});

export default trainingsReducer;
