import {
  Backdrop,
  Button,
  CircularProgress,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useAppDispatch, useAppSelector } from "hooks/storeHook";
import {
  NOTIFICATION_ASYNC_ALL,
  NOTIFICATION_CHANGED_EVENTS,
  NOTIFICATION_DELETE_ID,
} from "store/reducers/notifications/notifications.reducer";
import { useNavigate } from "react-router-dom";

const NotificationsPage: React.FC = () => {
  const state = useAppSelector((state) => state.notifications);
  const dispatch = useAppDispatch();
  const Navigation = useNavigate();

  const [deleteModal, setDeleteModal] = React.useState({ id: 0, open: false });
  const handleClickOpen = (id: number) => {
    setDeleteModal({
      open: true,
      id: id,
    });
  };
  const handleClose = () => {
    setDeleteModal({
      open: false,
      id: 0,
    });
  };
  const handleDeleteNotification = () => {
    console.log(deleteModal.id);
    dispatch(NOTIFICATION_DELETE_ID({ id: deleteModal.id }));
    handleClose();
    dispatch(NOTIFICATION_ASYNC_ALL());
  };

  const handleChange = (e: { target: { value: string } }) => {
    console.log(e.target.value);
    dispatch(NOTIFICATION_CHANGED_EVENTS({ page: 0, search: e.target.value }));
    dispatch(NOTIFICATION_ASYNC_ALL());
  };

  React.useEffect(() => {
    console.log(state.page + "-" + state.pageSize);
    dispatch(NOTIFICATION_ASYNC_ALL());
  }, [state.page, state.pageSize, state.search]);

  const columns: GridColDef[] = [
    { field: "title", headerName: "Título", width: 350 },
    {
      field: "scheduled",
      headerName: "Programado",
      width: 130,
      renderCell: (params: GridRenderCellParams<Date>) => {
        if (params.value && params.value !== null) {
          const datatime = new Date(params.value);
          return (
            <div>
              {`${datatime.getDate().toString().padStart(2, "0")}/${(
                datatime.getMonth() + 1
              )
                .toString()
                .padStart(2, "0")}/${datatime.getFullYear()} ${datatime
                  .getHours()
                  .toString()
                  .padStart(2, "0")}:${datatime
                    .getMinutes()
                    .toString()
                    .padStart(2, "0")}`}
            </div>
          );
        }
        return <></>;
      },
    },
    {
      field: "insertedAt",
      headerName: "Criado em",
      width: 150,
      renderCell: (params: GridRenderCellParams<Date>) => {
        const datatime = new Date(params.value);
        return (
          <div>
            {`${datatime.getDate().toString().padStart(2, "0")}/${(
              datatime.getMonth() + 1
            )
              .toString()
              .padStart(2, "0")}/${datatime.getFullYear()} ${datatime
                .getHours()
                .toString()
                .padStart(2, "0")}:${datatime
                  .getMinutes()
                  .toString()
                  .padStart(2, "0")}`}
          </div>
        );
      },
    },
    {
      field: "id",
      headerName: "Ações",
      width: 200,
      renderCell: (params: GridRenderCellParams<number>) => (
        <strong>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 16 }}
            onClick={() => {
              state.notifications.map((item) => {
                if (item.id === Number(params.value)) {
                  dispatch(NOTIFICATION_CHANGED_EVENTS({ notification: item }));
                }
              });
              Navigation(`/notifications/edit/${params.value}`);
            }}
          >
            Editar
          </Button>
          <Button
            variant="contained"
            color="error"
            size="small"
            style={{ marginLeft: 16 }}
            onClick={() => handleClickOpen(params.value)}
          >
            Excluir
          </Button>
        </strong>
      ),
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "85vh",
        background: (theme) => theme.palette.background.default,
      }}
    >
      <Typography variant="h5">Notificações</Typography>
      <Box sx={{ width: "90%", height: "70px", margin: "0 auto" }}>
        <TextField
          type="text"
          label="Pesquisar"
          fullWidth
          onChange={handleChange}
        />
      </Box>
      <Box sx={{ width: "90%", height: "95%", margin: "0 auto" }}>
        <DataGrid
          rows={state.notifications}
          columns={columns}
          pagination
          paginationMode="server"
          rowsPerPageOptions={[6, 10, 20]}
          pageSize={state.pageSize}
          page={state.page}
          rowCount={state.count}
          loading={state.loading}
          onPageChange={(_page) => {
            dispatch(NOTIFICATION_CHANGED_EVENTS({ page: _page }));
            //dispatch(NOTIFICATION_ASYNC_ALL());
          }}
          onPageSizeChange={(_size) => {
            dispatch(NOTIFICATION_CHANGED_EVENTS({ pageSize: _size }));
            //dispatch(NOTIFICATION_ASYNC_ALL());
          }}
        />
      </Box>
      {state.loading && (
        <Backdrop
          sx={{
            bgColor: "#3333",
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={state.loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Dialog
        open={deleteModal.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Deseja realmente excluir ?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Removerá a notificação
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleDeleteNotification} autoFocus>
            Remover
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default NotificationsPage;
