import { API_METHODS } from "services/api";
import {
  USER_ASYNC_ALL,
  USER_CREATE_ERROR,
  USER_CREATE_PAYLOAD,
  USER_CREATE_SUCCESS,
  USER_LOADING_SUCCESS,
  USER_CHANGED_STATE,
  USER_DELETE_ID,
} from "store/reducers/usuarios/users.reducer";
import {
  take,
  cancel,
  call,
  select,
  put,
  delay,
  takeLatest,
  fork,
} from "typed-redux-saga";

function* asyncGetUsers() {
  try {
    yield put(
      USER_CHANGED_STATE({
        loading: true,
      })
    );
    const token = yield* select((state) => state.auth.auth.token);
    const users = yield* select((state) => state.users);
    //yield console.log(token);
    const listUsers = yield* call(API_METHODS.GET_ALL_USERS, token, users.page, users.pageSize, users.search);
    //yield* delay(5000);
    yield put(USER_LOADING_SUCCESS(listUsers?.data));
    return listUsers;
  } catch (error) {
    USER_CHANGED_STATE({
      loading: false,
      error: true,
      msgError: "Erro ao tentar carregar usuários.",
    });
  } finally {
    yield put(
      USER_CHANGED_STATE({
        loading: false,
      })
    );
  }
}
function* asyncCreateUser(action: any) {
  const { payload } = action;
  yield put(
    USER_CHANGED_STATE({
      loading: true,
      success: false,
    })
  );
  //console.log(`asyncCreateUser`);
  const { id, ...normalize } = payload;
  const token = yield* select((state) => state.auth.auth.token);
  try {
    if (normalize.birthDate && normalize.birthDate !== null) {
      try {
        normalize.birthDate = normalize.birthDate.toISOString().substring(0, 10);
      } catch (e) {
        normalize.birthDate = null;
      }
    } else {
      normalize.birthDate = null;
    }
    //console.log(normalize.birthDate);
    let Request;
    //console.log(normalize);
    if ((id) && (id !== 0)) {
      //console.log(id);
      Request = yield* call(API_METHODS.UPDATE_A_USER, token, id, normalize);
    } else {
      Request = yield* call(API_METHODS.CREATE_A_USER, token, payload);
    }
    //console.log(Request);
    if (Request.error) {
      yield* put(
        USER_CHANGED_STATE({
          loading: false,
          error: true,
          msg: `${Request.error.data}`,
        })
      );
      return;
    }
    yield put(USER_CREATE_SUCCESS({ msg: "Usuário cadastrado corretamente" }));
    return Request;
  } catch (error) {
    console.log(error);
    yield* put(
      USER_CREATE_ERROR({ msg: "Ocorreu um erro ao cadastrar o usuário" })
    );
  } finally {
    yield put(
      USER_CHANGED_STATE({
        loading: false,
      })
    );
    yield console.log("FINALLY");
  }
}
function* asyncDeleteUser(action: any) {
  const { payload } = action;
  yield put(
    USER_CHANGED_STATE({
      loading: true,
      success: false,
    })
  );
  //console.log(`asyncDeleteUser`);
  //console.log(payload);
  const { id } = payload;
  const token = yield* select((state) => state.auth.auth.token);
  try {
    let Request;
    if ((id) && (id !== 0)) {
      //console.log(id);
      Request = yield* call(API_METHODS.DELETE_A_USER, token, id);
    }
    //console.log(Request);
    yield put(USER_CREATE_SUCCESS({ msg: "Usuário deletado corretamente" }));
    yield put(
      USER_CHANGED_STATE({
        loading: false,
        success: true,
        error: false,
      })
    );
    return Request;
  } catch (error) {
    yield* put(
      USER_CREATE_ERROR({ msg: "Ocorreu um erro ao deletar o usuário" })
    );
    yield put(
      USER_CHANGED_STATE({
        loading: false,
        success: false,
        error: true,
      })
    );
  } finally {
    /*yield put(
      USER_CHANGED_STATE({
        loading: false,
      })
    );*/
    yield console.log("FINALLY");
  }
}
export function* usersFlow() {
  while (true) {
    yield* take([USER_ASYNC_ALL.type]);
    const asyncAllUsers = yield* fork(asyncGetUsers);
    if (!asyncAllUsers) {
      yield* cancel(asyncAllUsers);
    }
    yield* takeLatest(USER_DELETE_ID.type, asyncDeleteUser);
  }
}
export function* userCreateFlow() {
  yield* takeLatest(USER_CREATE_PAYLOAD.type, asyncCreateUser);
}