import { Login } from "@mui/icons-material";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Paper,
  Slide,
  Stack,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/storeHook";
import React from "react";
import { useForm } from "react-hook-form";
import { AUTH_SEND_QUEST } from "store/reducers/auth/auth.reducer";

// import { Container } from './styles';

const AuthPage: React.FC = () => {
  const [animationState, setAnimationState] = React.useState(false);
  const { handleSubmit, register, reset } = useForm<{
    email: string;
    password: string;
  }>({
    defaultValues: {
      email: "",
      password: "",
    },
  });
  const dispatch = useAppDispatch();
  const Navigation = useNavigate();
  const authState = useAppSelector((state) => state.auth);

  React.useEffect(() => {
    setAnimationState(true);
    return () => setAnimationState(false);
  }, []);
  React.useEffect(() => {
    if (authState.auth?.user) {
      Navigation("dashboard");
    }
  }, [authState.auth?.user]);
  const handleSubmitAuth = (data: { email: string; password: string }) => {
    dispatch(AUTH_SEND_QUEST({ ...data }));
    reset();
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100vh",
        background: (theme) => theme.palette.background.default,
      }}
    >
      <Box sx={{ width: { xs: "90%", sm: "50%" }, margin: "0 auto" }}>
        <Slide direction="up" in={animationState} mountOnEnter unmountOnExit>
          <Stack component={Paper} direction="column" spacing={2} px={5} py={5}>
            <Box
              component="img"
              src="http://www.ramoestendido.com.br/wp-content/uploads/2014/02/logo.png"
              sx={{
                margin: "0 auto",
                width: "40%",
              }}
            />
            <Stack
              component="form"
              onSubmit={handleSubmit(handleSubmitAuth)}
              noValidate
              direction="column"
              spacing={2}
            >
              <TextField
                type="email"
                {...register("email")}
                fullWidth
                label="E-mail"
              />
              <TextField
                type="password"
                {...register("password")}
                fullWidth
                label="Senha"
              />
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  sx={{ margin: "0 auto" }}
                  endIcon={<Login />}
                  fullWidth
                >
                  ENTRAR NO SITE
                </Button>
              </Box>
            </Stack>
          </Stack>
        </Slide>
      </Box>
      {authState.loading && (
        <Backdrop
          sx={{
            bgColor: "#3333",
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={authState.loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </Box>
  );
};

export default AuthPage;
