import { useAppSelector } from "hooks/storeHook";
import AuthPage from "pages/auth/auth.page";
import DashBoardPage from "pages/dashboard/dashboard.page";
import EventsEditPage from "pages/events/events.edit.page";
import EventsNewPage from "pages/events/events.new.page";
import EventsPage from "pages/events/events.page";
import FeedsEditPage from "pages/feeds/feeds.edit.page";
import FeedsNewPage from "pages/feeds/feeds.new.page";
import FeedsPage from "pages/feeds/feeds.page";
import NotificationEditPage from "pages/notification/notification.edit.page";
import NotificationNewPage from "pages/notification/notification.new.page";
import NotificationsPage from "pages/notification/notification.page";
import TermsPage from "pages/terms/terms.page";
import TrainingRegistrationsPage from "pages/treinamentos/training.registrations.page";
import TraningEditPage from "pages/treinamentos/traning.edit.page";
import UsuariosPageEdit from "pages/usuarios/usuarios.edit.page";
import UsuariosPageNew from "pages/usuarios/usuarios.new.page";
import UserPage from "pages/usuarios/usuarios.page";
import React from "react";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import MainLayoute from "../layoutes/main.layoute";
import TrainingPage from "../pages/treinamentos/training.page";
import TraningNewPage from "../pages/treinamentos/traning.new.page";
import SocialMidiasEditPage from "../pages/socialmidias/socialmidias.edit.page";

// import { Container } from './styles';

const MainRoutes: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainLayoute />}>
          <Route index element={<AuthPage />} />
          <Route path="dashboard" element={<DashBoardPage />} />

          <Route path="operators/list" element={<UserPage />} />
          <Route path="operators/new" element={<UsuariosPageNew />} />
          <Route path="operators/edit/:id" element={<UsuariosPageEdit />} />

          {/*<Route path="eventos/list" element={<EventsPage />} />
          <Route path="eventos/new" element={<EventsNewPage />} />
          <Route path="eventos/edit/:id" element={<EventsEditPage />} />*/}

          <Route path="feeds/list" element={<FeedsPage />} />
          <Route path="feeds/new" element={<FeedsNewPage />} />
          <Route path="feeds/edit/:id" element={<FeedsEditPage />} />

          <Route path="events/list" element={<TrainingPage />} />
          <Route path="events/new" element={<TraningNewPage />} />
          <Route path="events/edit/:id" element={<TraningEditPage />} />
          <Route
            path="events/:id/registereds"
            element={<TrainingRegistrationsPage />}
          />

          <Route path="notifications/list" element={<NotificationsPage />} />
          <Route path="notifications/new" element={<NotificationNewPage />} />
          <Route path="notifications/edit/:id" element={<NotificationEditPage />} />

          <Route path="socialmidias" element={<SocialMidiasEditPage />} />
        </Route>
        <Route path="terms" element={<TermsPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default MainRoutes;
