import { School } from "@mui/icons-material";
import {
  Alert,
  Backdrop,
  Button,
  CircularProgress,
  Paper,
  Stack,
  Typography,
  Snackbar,
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { green } from "@mui/material/colors";
import { Box, styled } from "@mui/system";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import OptionsTraningForm from "components/tranings/options.form";
import QuestionsForm from "components/tranings/questions.form";
import TraningFormFirst from "components/tranings/traning.form";
import { useAppDispatch, useAppSelector } from "hooks/storeHook";
import React, { useState } from "react";
import { useForm, FormProvider, useFieldArray } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { TRAINING_CREATE_PAYLOAD } from "store/reducers/trainings/trainings.reducer";

const Input = styled("input")({
  display: "none",
});

const TraningEditPage: React.FC = () => {
  const [animationState, setAnimationState] = React.useState(false);
  React.useEffect(() => {
    setAnimationState(true);
    return () => setAnimationState(false);
  }, []);

  const Navigation = useNavigate();
  const dispatch = useAppDispatch();
  //const { id } = useParams<{ id: string }>();
  const trainingsState = useAppSelector((state) => state.trainings);

  const methods = useForm({
    defaultValues: trainingsState.training,
  });
  const { handleSubmit, control, register, setValue, getValues, reset, watch } =
    methods;

  var [imageQuestionUrl, setImageQuestionUrl] = useState("");
  const onImageQuestionChange = (e: any) => {
    imageQuestionUrl = "";
    const file = e.target.files[0];
    try {
      imageQuestionUrl = URL.createObjectURL(file);
      setValue(
        `questions.${questionModal.index}.imageQuestion`,
        e.target.files
      );
    } catch (error) {
      console.log(error);
    }
    setImageQuestionUrl(imageQuestionUrl);
  };

  const { append, fields, remove, update } = useFieldArray({
    name: "questions",
    control,
  });

  const appendToFormQuestions = React.useCallback(() => {
    var newIndex = 0;
    var newQuestion = {
      name: "",
      description: "",
      imageUrl: null,
      imageQuestion: undefined,
      type: "info",
      valueType: "text",
      minimum: 0,
      maximum: 0,
      options: [],
    };
    append(newQuestion);
    Array.from(getValues("questions")).map((field: any, fieldIndex) => {
      if (field.name === "" && field.description === "") {
        newIndex = fieldIndex;
        newQuestion = field;
      }
    });
    showQuestionModal(newIndex, newQuestion, false);
  }, [append]);

  const [questionModal, setQuestionModal] = React.useState({
    open: false,
    index: -1,
    editing: false,
    question: {
      id: "",
      name: "",
      description: "",
      imageUrl: null,
      imageQuestion: undefined,
      type: "info",
      valueType: "text",
      minimum: 0,
      maximum: 0,
      options: [],
    },
  });
  const showQuestionModal = (
    index: number,
    question: any,
    editing: boolean
  ) => {
    imageQuestionUrl = "";
    try {
      if (index >= 0) {
        const imageQuestion = getValues(`questions.${index}.imageQuestion`);
        if (imageQuestion !== undefined && imageQuestion !== null) {
          imageQuestionUrl = URL.createObjectURL(imageQuestion[0] || "");
        } else {
          const imageUrl = getValues(`questions.${index}.imageUrl`);
          //console.log(imageUrl);
          if (imageUrl !== undefined && imageUrl !== null && imageUrl !== "") {
            imageQuestionUrl = imageUrl;
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
    setImageQuestionUrl(imageQuestionUrl);

    setQuestionModal({
      open: true,
      index: index,
      editing: editing,
      question: question,
    });
  };
  const closeQuestionModal = () => {
    if (!questionModal.editing) {
      remove(questionModal.index);
    }
    clearQuestionModal();
  };
  const clearQuestionModal = () => {
    setQuestionModal({
      open: false,
      index: -1,
      editing: true,
      question: {
        id: "",
        name: "",
        description: "",
        imageUrl: null,
        imageQuestion: undefined,
        type: "info",
        valueType: "text",
        minimum: 0,
        maximum: 0,
        options: [],
      },
    });
  };
  const handleSaveQuestion = () => {
    update(questionModal.index, getValues().questions[questionModal.index]);
    clearQuestionModal();
  };

  const Submit = (data: any) => {
    //console.log(data);
    dispatch(TRAINING_CREATE_PAYLOAD({ ...data }));
    if (
      trainingsState.success !== undefined &&
      trainingsState.success !== null &&
      trainingsState.success === true
    ) {
      reset();
      Navigation("/events/list");
    }
  };

  React.useEffect(() => {
    if (
      trainingsState.success !== undefined &&
      trainingsState.success !== null &&
      trainingsState.success === true
    ) {
      reset();
      Navigation("/events/list");
    }
  }, [trainingsState.success]);

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Nome",
      width: 200,
    },
    { field: "description", headerName: "Descrição", width: 200 },
    {
      field: "type",
      headerName: "Tipo",
      width: 80,
      renderCell: (params: GridRenderCellParams<string>) => {
        return <div>{`${params.value}`}</div>;
      },
    },
    {
      field: "id",
      headerName: "Ações",
      width: 200,
      renderCell: (params: GridRenderCellParams<String>) => (
        <strong>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 16 }}
            //onClick={() => Navigation(`/feeds/edit/${params.value}`)}
            onClick={() => {
              //console.log(`Editar=${params.value}`);
              fields.map((questionsItem, questionIndex) => {
                if (questionsItem.id === params.value) {
                  //console.log(questionsItem);
                  showQuestionModal(questionIndex, questionsItem, true);
                }
              });
            }}
          >
            Editar
          </Button>
          <Button
            variant="contained"
            color="error"
            size="small"
            style={{ marginLeft: 16 }}
            onClick={() => {
              //console.log(`Editar=${params.value}`);
              fields.map((questionsItem, questionIndex) => {
                if (questionsItem.id === params.value) {
                  //console.log(questionsItem);
                  //showQuestionModal(questionIndex, questionsItem);
                  remove(questionIndex);
                }
              });
            }}
          >
            Excluir
          </Button>
        </strong>
      ),
    },
  ];

  return (
    <Slide direction="up" in={animationState} mountOnEnter unmountOnExit>
      <Box sx={{ width: "80%", height: "100vh", margin: "0 auto" }}>
        <FormProvider {...methods}>
          <Paper
            component="form"
            sx={{ width: "100%" }}
            noValidate
            onSubmit={handleSubmit(Submit)}
          >
            <Box
              py={2}
              sx={{ display: "flex", flexDirection: "column" }}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <School sx={{ fontSize: "3.5em", color: green[500] }} />
              <Typography variant="h5">Editar Evento</Typography>
            </Box>
            <TraningFormFirst />
            <Box sx={{ width: "80%", margin: " 0 auto " }}>
              <Button
                variant="contained"
                color="warning"
                fullWidth
                onClick={appendToFormQuestions}
              >
                ADICIONAR PERGUNTA
              </Button>
            </Box>
            <Box sx={{ width: "90%", height: "400px", margin: "0 auto" }}>
              <DataGrid
                rows={fields}
                columns={columns}
                pagination
                paginationMode="client"
                rowsPerPageOptions={[5]}
                pageSize={5}
              //onRowClick={(params) => console.log(params)}
              />
            </Box>
            <Box
              py={2}
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "90%",
                margin: "0 auto",
              }}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Button
                type="submit"
                color="primary"
                variant="contained"
                fullWidth
              >
                SALVAR
              </Button>
            </Box>
          </Paper>
          <Dialog
            open={questionModal.open}
            onClose={closeQuestionModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth="md"
          >
            <DialogTitle id="alert-dialog-title">{"Pergunta"}</DialogTitle>
            <DialogContent>
              <Stack direction="column" spacing={2} p={5} mb={5}>
                <TextField
                  type="text"
                  label="Nome da pergunta"
                  {...register(`questions.${questionModal.index}.name`)}
                  fullWidth
                  variant="outlined"
                  inputProps={{
                    maxLength: 100,
                  }}
                />
                <TextField
                  type="text"
                  multiline
                  rows={3}
                  label="Descrição da pergunta"
                  {...register(`questions.${questionModal.index}.description`)}
                  fullWidth
                  variant="outlined"
                  inputProps={{
                    maxLength: 300,
                  }}
                />
                <Stack direction="row" spacing={2}>
                  <Box sx={{ width: "50%" }}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Tipo da Pergunta
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Tipo da Pergunta"
                        {...register(`questions.${questionModal.index}.type`)}
                        defaultValue={getValues(
                          `questions.${questionModal.index}.type`
                        )}
                      >
                        <MenuItem value={"info"}>Informativo</MenuItem>
                        <MenuItem value={"input"}>Campo livre</MenuItem>
                        <MenuItem value={"options"}>Seleção</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  {watch(`questions.${questionModal.index}.type`) ===
                    "input" && (
                      <Box sx={{ width: "50%" }}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Tipo da Resposta
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Tipo da Resposta"
                            {...register(
                              `questions.${questionModal.index}.valueType`
                            )}
                            //defaultValue={"text"}
                            defaultValue={getValues(
                              `questions.${questionModal.index}.valueType`
                            )}
                          >
                            <MenuItem value={"text"}>Texto</MenuItem>
                            <MenuItem value={"int"}>Inteiro</MenuItem>
                            <MenuItem value={"num"}>Numérico</MenuItem>
                            <MenuItem value={"date"}>Data</MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                    )}
                </Stack>
                {watch(`questions.${questionModal.index}.type`) !== "info" && (
                  <Stack direction="row" spacing={2}>
                    <Box sx={{ width: "50%" }}>
                      <TextField
                        type="number"
                        label="Mínimo"
                        {...register(
                          `questions.${questionModal.index}.minimum`
                        )}
                        fullWidth
                        variant="outlined"
                      />
                    </Box>
                    <Box sx={{ width: "50%" }}>
                      <TextField
                        type="number"
                        label="Máximo"
                        {...register(
                          `questions.${questionModal.index}.maximum`
                        )}
                        fullWidth
                        variant="outlined"
                      />
                    </Box>
                  </Stack>
                )}
                {imageQuestionUrl !== "" ? (
                  <img
                    src={imageQuestionUrl}
                    alt={""}
                    style={{ maxWidth: "150px" }}
                  />
                ) : (
                  ""
                )}
                {watch(`questions.${questionModal.index}.type`) === "info" && (
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <label htmlFor="contained-button-question-file">
                      <Input
                        accept="image/*"
                        id="contained-button-question-file"
                        type="file"
                        {...register(
                          `questions.${questionModal.index}.imageQuestion`
                        )}
                        onChange={onImageQuestionChange}
                      />
                      <Button variant="contained" component="span">
                        Selecionar imagem
                      </Button>
                    </label>
                  </Stack>
                )}
                {watch(`questions.${questionModal.index}.type`) ===
                  "options" && (
                    <>
                      <Stack
                        direction="row"
                        sx={{ width: "100%", margin: "0 auto", marginBottom: 5 }}
                        spacing={1}
                      >
                        <Button
                          variant="contained"
                          color="warning"
                          fullWidth
                          onClick={() =>
                            setValue(
                              `questions.${questionModal.index}.options`,
                              [
                                ...getValues().questions[questionModal.index]
                                  .options,
                                { name: "", default: false },
                              ],
                              ...getValues().questions
                            )
                          }
                        >
                          ADICIONAR OPÇÃO
                        </Button>
                      </Stack>
                      <OptionsTraningForm
                        control={control}
                        register={register}
                        nestIndex={questionModal.index}
                      />
                    </>
                  )}
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeQuestionModal}>Cancelar</Button>
              <Button onClick={handleSaveQuestion} autoFocus>
                Salvar
              </Button>
            </DialogActions>
          </Dialog>
        </FormProvider>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={trainingsState.loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Snackbar
          open={trainingsState.success || false}
          autoHideDuration={6000}
        >
          <Alert severity="success" sx={{ width: "100%" }}>
            Treinamento Cadastrado com Sucesso!
          </Alert>
        </Snackbar>
      </Box>
    </Slide>
  );
};

export default TraningEditPage;
