import { Person } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
  Slide,
  Backdrop,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import { green } from "@mui/material/colors";
import { useAppDispatch, useAppSelector } from "hooks/storeHook";
import React from "react";
import { useForm } from "react-hook-form";
import { USER_CREATE_PAYLOAD } from "store/reducers/usuarios/users.reducer";
import { useNavigate, useParams } from "react-router-dom";

// import { Container } from './styles';

const UsuariosPageNew: React.FC = () => {
  const [animationState, setAnimationState] = React.useState(false);
  const dispatchAction = useAppDispatch();
  const Navigation = useNavigate();
  const { register, watch, handleSubmit } = useForm<{
    active: boolean;
    type: any;
    name: string;
    birthDate: Date;
    email: string;
    userName: string;
    password: string;
  }>();
  React.useEffect(() => {
    setAnimationState(true);
    return () => setAnimationState(false);
  }, []);
  const usersState = useAppSelector((state) => state.users);
  const Submit = (data: any) => {
    dispatchAction(USER_CREATE_PAYLOAD({ ...data }));
    /*if (success !== undefined && success !== null && success === true) {
      Navigation("/operators/list");
    }*/
  };
  React.useEffect(() => {
    if (
      usersState.success !== undefined &&
      usersState.success !== null &&
      usersState.success === true
    ) {
      Navigation("/operators/list");
    }
  }, [usersState.success]);
  return (
    <>
      <Box
        sx={{
          width: "95%",
          margin: "0 auto",
          height: "100vh",
          mt: { xs: 5, sm: 0 },
        }}
      >
        <Slide direction="up" in={animationState} mountOnEnter unmountOnExit>
          <Box
            component={Paper}
            py={5}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              flexBasis: 1,
              alignItems: "center",
              width: "80%",
              margin: "0 auto",
            }}
          >
            <Box
              py={2}
              sx={{ display: "flex", flexDirection: "column" }}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Person sx={{ fontSize: "3.5em", color: green[500] }} />
              <Typography variant="h5">Cadastro de Operador</Typography>
            </Box>
            <Stack
              component={"form"}
              noValidate
              direction={"column"}
              spacing={5}
              sx={{ width: "80%" }}
              onSubmit={handleSubmit(Submit)}
            >
              <Stack direction={"row"} spacing={2}>
                <TextField
                  type="text"
                  label="Nome"
                  {...register("name")}
                  fullWidth
                  inputProps={{
                    maxLength: 100,
                  }}
                />
                <FormControl component="fieldset" variant="standard">
                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        {...register("active")}
                        defaultChecked={true}
                      />
                    }
                    label="Ativo"
                    sx={{
                      ".MuiFormControlLabel-label": {
                        color: green["500"],
                      },
                    }}
                  />
                </FormControl>
              </Stack>
              <Stack direction={"row"} spacing={2}>
                <TextField
                  type="date"
                  InputLabelProps={{ shrink: true, required: false }}
                  label="Data de Nascimento"
                  {...register("birthDate", { valueAsDate: true })}
                  fullWidth
                />
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Tipo de usuario
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Tipo de usuario"
                    {...register("type")}
                  >
                    <MenuItem value={"admin"}>Administrador</MenuItem>
                    {
                      //<MenuItem value={"operator"}>Operador</MenuItem>
                    }
                    <MenuItem value={"app"}>Aplicativo</MenuItem>
                  </Select>
                </FormControl>
              </Stack>
              <TextField
                type="email"
                label="E-mail"
                fullWidth
                {...register("email")}
                inputProps={{
                  maxLength: 244,
                }}
              />
              <Stack direction={"row"} spacing={2}>
                <TextField
                  type="text"
                  label="Usuario"
                  {...register("userName")}
                  fullWidth
                  inputProps={{
                    maxLength: 244,
                  }}
                />
                <TextField
                  type="password"
                  label="Senha"
                  fullWidth
                  {...register("password")}
                  inputProps={{
                    maxLength: 244,
                  }}
                />
              </Stack>
              <Button
                color="primary"
                type="submit"
                variant="contained"
                fullWidth
              >
                Salvar
              </Button>
            </Stack>
          </Box>
        </Slide>
      </Box>
      <Backdrop
        sx={{
          bgColor: "#3333",
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={usersState.loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar open={usersState.success || false} autoHideDuration={6000}>
        <Alert severity="success" sx={{ width: "100%" }}>
          Evento cadastrado com sucesso!
        </Alert>
      </Snackbar>
      <Snackbar open={usersState.error || false} autoHideDuration={6000}>
        <Alert severity="error" sx={{ width: "100%" }}>
          {usersState.msg || "Erro ao tentar cadastrar o evendo"}
        </Alert>
      </Snackbar>
    </>
  );
};

export default UsuariosPageNew;
