import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Stack,
  Switch,
  TextField,
} from "@mui/material";
import { green } from "@mui/material/colors";
import React from "react";
import { Control, useFieldArray } from "react-hook-form";

// import { Container } from './styles';

const OptionsTraningForm = ({
  nestIndex,
  control,
  register,
}: {
  nestIndex: number;
  control: Control<any>;
  register: any;
}): JSX.Element => {
  const { fields, remove, append } = useFieldArray({
    control,
    name: `questions.${nestIndex}.options`,
  });
  /*React.useEffect(() => {
    console.log(fields);
  }, [fields]);*/
  return (
    <>
      {fields.map((optItem, optIndex) => {
        return (
          <Stack
            direction="row"
            spacing={3}
            key={optItem.id}
            sx={{ width: "100%" }}
          >
            <TextField
              type="text"
              label="Nome da opção"
              {...register(`questions.${nestIndex}.options.${optIndex}.name`)}
              fullWidth
              variant="outlined"
            />
            <FormControl component="fieldset" variant="standard">
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    {...register(
                      `questions.${nestIndex}.options.${optIndex}.default`
                    )}
                  />
                }
                label="Padrão"
                sx={{
                  ".MuiFormControlLabel-label": {
                    color: green["500"],
                  },
                }}
              />
            </FormControl>
            <Box component="div">
              <Button
                variant="contained"
                color="error"
                fullWidth
                onClick={() => remove(optIndex)}
              >
                REMOVER
              </Button>
            </Box>
          </Stack>
        );
      })}
    </>
  );
};

export default OptionsTraningForm;
