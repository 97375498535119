/* eslint-disable @typescript-eslint/no-unused-expressions */
import { API_METHODS } from "services/api";
import {
  FEED_ASYNC_ALL,
  FEED_CREATE_PAYLOAD,
  FEED_CHANGED_EVENTS,
  FEED_LOADING_SUCCESS,
  FEED_CREATE_SUCCESS,
  FEED_CREATE_ERROR,
  FEED_DELETE_ID,
} from "store/reducers/feeds/feeds.reducer";
import { takeLatest, fork, take, select, call, put, cancel, delay } from "typed-redux-saga";

function* getFeeds() {
  try {
    yield put(
      FEED_CHANGED_EVENTS({
        loading: true,
        success: false,
        error: false,
      })
    );
    const token = yield* select((state) => state.auth.auth.token);
    const feeds = yield* select((state) => state.feeds);
    const Request = yield* call(API_METHODS.GET_ALL_FEEDS, token, feeds.page, feeds.pageSize, feeds.search);
    yield put(FEED_LOADING_SUCCESS(Request?.data));
    return Request;
  } catch (error) {
    yield put(
      FEED_CHANGED_EVENTS({
        loading: false,
        success: false,
        error: true,
      })
    );
    return error;
  } finally {
    yield put(
      FEED_CHANGED_EVENTS({
        loading: false,
      })
    );
  }
}
function* getAllFeedsFoork() {
  const getFeed = yield* call(getFeeds);
  //console.log("REST", getFeed);
}
function* createFeedSaga(action: any) {
  const token = yield* select((state) => state.auth.auth.token);
  const { id, image, imageUrl, ...normalize } = action.payload;

  try {
    var result;
    yield* put(FEED_CHANGED_EVENTS({ loading: true, success: false, error: false }));
    if (id !== undefined && id > 0) {
      result = yield* call(API_METHODS.UPDATE_A_FEEDS, token, id, normalize);
    } else {
      result = yield* call(API_METHODS.CREATE_A_FEEDS, token, normalize);
    }
    if (result.data.id) {
      //console.log(result.data);
      if (image && image !== null && image.length > 0) {
        //console.log(image);
        const imageUpload = new FormData();
        Array.from(image).map((img: any) => {
          imageUpload.append("image", img);
        });
        const imageIsUploaded = yield* call(
          API_METHODS.UPLOAD_A_FEEDS,
          token,
          result.data.id,
          imageUpload
        );
        //console.log(imageIsUploaded);
      }

      yield* put(FEED_CHANGED_EVENTS({ loading: false, success: true, error: false }));
    }
  } catch (error) {
    yield* put(
      FEED_CHANGED_EVENTS({
        loading: false,
        error: true,
        msgError: "Ocorreu um erro a cadastrar feed",
      })
    );
  } finally {
    yield* put(FEED_CHANGED_EVENTS({ loading: false }));
  }
}
function* asyncDeleteFeed(action: any) {
  const { payload } = action;
  yield* put(
    FEED_CHANGED_EVENTS({
      loading: true,
      success: false,
    })
  );
  //console.log(`asyncDeleteFeed`);
  //console.log(payload);
  const { id } = payload;
  const token = yield* select((state) => state.auth.auth.token);
  try {
    let Request;
    if ((id) && (id !== 0)) {
      //console.log(id);
      Request = yield* call(API_METHODS.DELETE_A_FEEDS, token, id);
    }
    //console.log(Request);
    yield* put(FEED_CREATE_SUCCESS({ msg: "Feed deletado corretamente" }));
    yield* put(
      FEED_CHANGED_EVENTS({
        loading: false,
        success: true,
        error: false,
      })
    );
    return Request;
  } catch (error) {
    yield* put(
      FEED_CREATE_ERROR({ msg: "Ocorreu um erro ao deletar o feed" })
    );
    yield* put(
      FEED_CHANGED_EVENTS({
        loading: false,
        success: false,
        error: true,
      })
    );
  } finally {
    /*yield put(
      USER_CHANGED_STATE({
        loading: false,
      })
    );*/
    yield console.log("FINALLY");
  }
}
export function* feedsSaga() {
  while (true) {
    yield* take([FEED_ASYNC_ALL.type]);
    const asyncAllUsers = yield* fork(getAllFeedsFoork);
    if (!asyncAllUsers) {
      yield* cancel(asyncAllUsers);
    }
    yield* takeLatest(FEED_DELETE_ID.type, asyncDeleteFeed);
  }
}
export function* feedCreateSaga() {
  yield* takeLatest(FEED_CREATE_PAYLOAD.type, createFeedSaga);
}