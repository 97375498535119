import { Event, Feed, Person } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Paper,
  Stack,
  Switch,
  TextField,
  Typography,
  Slide,
  Backdrop,
  CircularProgress,
  Alert,
} from "@mui/material";
import { green } from "@mui/material/colors";
import { styled } from "@mui/system";
import { useAppDispatch, useAppSelector } from "hooks/storeHook";
import React from "react";
import { useForm } from "react-hook-form";
import Snackbar from "@mui/material/Snackbar";
import { FEED_CREATE_PAYLOAD } from "store/reducers/feeds/feeds.reducer";
import { useNavigate, useParams } from "react-router-dom";
import ReactPlayer from "react-player";
import { apiUrl } from "services/api";

const Input = styled("input")({
  display: "none",
});

const FeedsEditPage: React.FC = () => {
  const [animationState, setAnimationState] = React.useState(false);
  const dispatchAction = useAppDispatch();
  const Navigation = useNavigate();
  const { id } = useParams<{ id: string }>();
  const feedsState = useAppSelector((state) => state.feeds);
  const { register, watch, handleSubmit, setValue, getValues, reset } = useForm(
    {
      defaultValues: feedsState.feed,
    }
  );

  React.useEffect(() => {
    setAnimationState(true);
    return () => setAnimationState(false);
  }, []);

  const Submit = (data: any) => {
    dispatchAction(FEED_CREATE_PAYLOAD({ ...data }));
  };

  const imageWath = watch("image");
  const imageUrlWath = watch("imageUrl");

  const getImage = () => {
    if (imageWath !== undefined) {
      try {
        const url = URL.createObjectURL(imageWath[0]);
        //return <img src={url} alt={""} style={{ maxWidth: "150px" }} />;
        return imageWath[0].type.match("image") ? (
          <img
            src={url}
            alt={""}
            style={{ maxWidth: "150px" }}
          />
        ) : (
          <ReactPlayer
            url={[
              {
                src: url,
                type: "video/mp4",
              },
            ]}
            controls={true}
          />
        )
      } catch (error) {
        console.log(error);
      }
    }
    if (imageUrlWath !== undefined && imageUrlWath !== null && imageUrlWath !== "") {
      if (imageUrlWath.endsWith(".mp4")) {
        return (
          <ReactPlayer
            url={`${apiUrl}feed-new/${id}/image`}
            playing={false}
            controls={true}
          />
        );
      }
      return <img src={imageUrlWath} alt={""} style={{ maxWidth: "150px" }} />;
    }
    return <></>;
  };

  const getDefaultDate = (nameValue: string) => {
    const value = getValues(nameValue);
    if (value === undefined || value === null || value === "") {
      return null;
    }
    const datetime = new Date(value);
    const result = datetime.toISOString().substring(0, 16);
    //console.log(result);
    return result;
  };

  return (
    <Slide direction="up" in={animationState} mountOnEnter unmountOnExit>
      <Box
        sx={{
          width: "95%",
          margin: "0 auto",
          height: "100vh",
          mt: { xs: 5, sm: 0 },
        }}
      >
        <Box
          component={Paper}
          py={5}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            flexBasis: 1,
            alignItems: "center",
            width: "80%",
            margin: "0 auto",
          }}
        >
          <Box
            py={2}
            sx={{ display: "flex", flexDirection: "column" }}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Feed sx={{ fontSize: "3.5em", color: green[500] }} />
            <Typography variant="h5">Cadastro de feeds</Typography>
          </Box>
          <Stack
            component={"form"}
            noValidate
            direction={"column"}
            spacing={5}
            sx={{ width: "80%" }}
            onSubmit={handleSubmit(Submit)}
          >
            <Stack direction="row" spacing={2}>
              <Box
                sx={{ display: "flex", flexDirection: "column", width: "50%" }}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <FormControl component="fieldset" variant="standard">
                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        {...register("active")}
                        defaultChecked={getValues("active")}
                      />
                    }
                    label="Ativo"
                    sx={{
                      ".MuiFormControlLabel-label": {
                        color: green["500"],
                      },
                    }}
                  />
                </FormControl>
              </Box>
              <Box
                sx={{ display: "flex", flexDirection: "column", width: "100%" }}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <FormControl component="fieldset" variant="standard">
                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        {...register("fixed")}
                        defaultChecked={getValues("fixed")}
                      />
                    }
                    label="Fixar no topo do app"
                    sx={{
                      ".MuiFormControlLabel-label": {
                        color: green["500"],
                      },
                    }}
                  />
                </FormControl>
              </Box>
            </Stack>
            <TextField
              type="text"
              fullWidth
              InputLabelProps={{ shrink: true, required: true }}
              label={"Título"}
              {...register("title")}
              inputProps={{
                maxLength: 200,
              }}
            />
            <TextField
              type="text"
              fullWidth
              multiline
              InputLabelProps={{ shrink: true, required: true }}
              label={"Descrição"}
              {...register("description", {
                required: "Descrição obrigatória",
              })}
              rows={3}
              inputProps={{
                maxLength: 300,
              }}
            />
            <TextField
              type="url"
              label="Link externo"
              InputLabelProps={{ shrink: true, required: false }}
              {...register("externalUrl")}
              fullWidth
              variant="outlined"
              inputProps={{
                maxLength: 244,
              }}
            />
            {getImage()}
            <Stack direction="row" alignItems="center" spacing={2}>
              <label htmlFor="contained-button-file">
                <Input
                  //accept="image/*,video/mp4"
                  accept="image/*"
                  id="contained-button-file"
                  type="file"
                  {...register("image")}
                />
                <Button variant="contained" component="span">
                  Selecionar imagem
                </Button>
              </label>
            </Stack>
            <TextField
              type="datetime-local"
              label="Data e hora programada"
              InputLabelProps={{ shrink: true, required: true }}
              {...register("scheduled", {
                valueAsDate: true,
                required: "Data e hora programada obrigatória",
              })}
              defaultValue={getDefaultDate("scheduled")}
              fullWidth
            />
            <Button color="primary" type="submit" variant="contained" fullWidth>
              Salvar
            </Button>
          </Stack>
        </Box>
        <Backdrop
          sx={{
            bgColor: "#3333",
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={feedsState.loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Snackbar open={feedsState.success || false} autoHideDuration={6000} onClose={() => Navigation("/feeds/list")}>
          <Alert severity="success" sx={{ width: "100%" }}>
            Feed Cadastrado com sucesso
          </Alert>
        </Snackbar>
        <Snackbar open={feedsState.error || false} autoHideDuration={6000}>
          <Alert severity="error" sx={{ width: "100%" }}>
            {feedsState.msg}
          </Alert>
        </Snackbar>
      </Box>
    </Slide>
  );
};

export default FeedsEditPage;
