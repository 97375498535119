import { AnyAction, createAction, createReducer } from "@reduxjs/toolkit";

export const FEED_CREATE_PAYLOAD = createAction<{ [key: string]: any }>(
  "FEED_CREATE_PAYLOAD"
);
export const FEED_CREATE_SUCCESS = createAction<{ msg: string }>(
  "FEED_CREATE_SUCCESS"
);
export const FEED_CREATE_ERROR =
  createAction<{ msg: string }>("FEED_CREATE_ERROR");
export const FEED_ASYNC_ALL = createAction("FEED_ASYNC_ALL");
export const FEED_CHANGED_EVENTS = createAction<{ [key: string]: any }>(
  "FEED_CHANGED_EVENTS"
);
export const FEED_LOADING_SUCCESS = createAction<{
  data: Array<Partial<any>>;
}>("FEED_LOADING_SUCCESS");
export const FEED_SET_TO_STORE =
  createAction<{ [key: string]: any }>("FEED_SET_TO_STORE");
export const FEED_DELETE_ID = createAction<{ id: any }>(
  "FEED_DELETE_ID"
);
const initalState: {
  id: number;
  loading: boolean;
  feeds: Array<Partial<any>>;
  success: boolean;
  error: boolean;
  msg: string;
  count: number;
  page: number;
  pageSize: number;
  search: string;
  feed: Partial<any>;
} = {
  id: 0,
  loading: false,
  feeds: [],
  success: false,
  error: false,
  msg: "",
  count: 0,
  page: 0,
  pageSize: 6,
  search: "",
  feed: {},
};
const feedsReducer = createReducer(initalState, (build) => {
  build
    .addCase(FEED_CHANGED_EVENTS.type, (state, action: AnyAction) => {
      return {
        ...state,
        ...action.payload,
      };
    })
    .addCase(FEED_LOADING_SUCCESS.type, (state, action: AnyAction) => {
      return {
        ...state,
        feeds: action.payload.data,
        count: action.payload.count,
      };
    })
    .addCase(FEED_CREATE_SUCCESS.type, (state, action: AnyAction) => {
      state.error = false;
      state.success = true;
      state.feeds = action.payload;
    })
    .addCase(FEED_CREATE_ERROR.type, (state, action: AnyAction) => {
      state.error = false;
      state.success = false;
      state.msg = action.payload;
    });
});

export default feedsReducer;
