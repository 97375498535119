/* eslint-disable @typescript-eslint/no-unused-expressions */
import { API_METHODS } from "services/api";
import {
  SOCIALMIDIA_CREATE_PAYLOAD,
  SOCIALMIDIA_CHANGED_EVENTS,
  SOCIALMIDIA_CREATE_SUCCESS,
  SOCIALMIDIA_CREATE_ERROR,
  SOCIALMIDIA_ASYNC_ALL,
  SOCIALMIDIA_LOADING_SUCCESS,
  SOCIALMIDIA_DELETE_ID,
} from "store/reducers/socialmidias/socialmidias.reducer";
import { takeLatest, fork, take, select, call, put, cancel, takeEvery } from "typed-redux-saga";

function* getNotifications() {
  try {
    yield put(
      SOCIALMIDIA_CHANGED_EVENTS({
        loading: true,
        success: false,
        error: false,
      })
    );
    const token = yield* select((state) => state.auth.auth.token);
    const feeds = yield* select((state) => state.feeds);
    const Request = yield* call(API_METHODS.GET_ALL_SOCIALMIDIA, token, feeds.page, feeds.pageSize, feeds.search);
    yield put(SOCIALMIDIA_LOADING_SUCCESS(Request?.data));
    return Request;
  } catch (error) {
    yield put(
      SOCIALMIDIA_CHANGED_EVENTS({
        loading: false,
        success: false,
        error: true,
      })
    );
    return error;
  } finally {
    yield put(
      SOCIALMIDIA_CHANGED_EVENTS({
        loading: false,
      })
    );
  }
}
function* getAllSocialMidiasFoork() {
  const getNotify = yield* call(getNotifications);
  //console.log("REST", getNotify);
}
function* createSocialMidiaSaga(action: any) {
  //console.log("createNotificationSaga");
  const token = yield* select((state) => state.auth.auth.token);
  try {
    const { id, ...normalize } = action.payload;
    yield* put(SOCIALMIDIA_CHANGED_EVENTS({ loading: true, success: false, error: false }));
    //console.log(action.payload);
    var result;
    if (id !== undefined && id > 0) {
      result = yield* call(API_METHODS.UPDATE_A_SOCIALMIDIA, token, id, normalize);
    } else {
      result = yield* call(API_METHODS.CREATE_A_SOCIALMIDIA, token, normalize);
    }
    yield put(SOCIALMIDIA_CREATE_SUCCESS({ msg: "Notificação enviada com sucesso" }));
    yield put(
      SOCIALMIDIA_CHANGED_EVENTS({
        loading: false,
        success: true,
        error: false,
      })
    );
    return result;
  } catch (error) {
    yield* put(
      SOCIALMIDIA_CREATE_ERROR({ msg: "Ocorreu um erro ao deletar o usuário" })
    );
    yield put(
      SOCIALMIDIA_CHANGED_EVENTS({
        loading: false,
        success: false,
        error: true,
      })
    );
  } finally {
    //yield* put(NOTIFICATION_CHANGED_EVENTS({ loading: false }));
  }
}
function* asyncDeleteSocialMidia(action: any) {
  const { payload } = action;
  yield* put(
    SOCIALMIDIA_CHANGED_EVENTS({
      loading: true,
      success: false,
    })
  );
  //console.log(`asyncDeleteNotification`);
  //console.log(payload);
  const { id } = payload;
  const token = yield* select((state) => state.auth.auth.token);
  try {
    let Request;
    if ((id) && (id !== 0)) {
      //console.log(id);
      Request = yield* call(API_METHODS.DELETE_A_SOCIALMIDIA, token, id);
    }
    //console.log(Request);
    yield* put(SOCIALMIDIA_CREATE_SUCCESS({ msg: "Mídia deletado corretamente" }));
    yield* put(
      SOCIALMIDIA_CHANGED_EVENTS({
        loading: false,
        success: true,
        error: false,
      })
    );
    return Request;
  } catch (error) {
    yield* put(
      SOCIALMIDIA_CREATE_ERROR({ msg: "Ocorreu um erro ao deletar a notificação" })
    );
    yield* put(
      SOCIALMIDIA_CHANGED_EVENTS({
        loading: false,
        success: false,
        error: true,
      })
    );
  } finally {
    /*yield put(
      USER_CHANGED_STATE({
        loading: false,
      })
    );*/
    yield console.log("FINALLY");
  }
}
export function* socialMidiasSaga() {
  while (true) {
    yield* take([SOCIALMIDIA_ASYNC_ALL.type]);
    const asyncAllUsers = yield* fork(getAllSocialMidiasFoork);
    if (!asyncAllUsers) {
      yield* cancel(asyncAllUsers);
    }
    //yield* takeLatest(NOTIFICATION_DELETE_ID.type, asyncDeleteNotification);
  }
}
export function* socialMidiaSaga() {
  /*while (true) {
    const valid = yield* take(NOTIFICATION_CREATE_PAYLOAD.type) as any;
    const autoLoopValidate = yield* call(createNotificationSaga, valid);
    if (!autoLoopValidate) {
      yield* cancel(autoLoopValidate);
    }
  }*/
  yield* takeLatest(SOCIALMIDIA_CREATE_PAYLOAD.type, createSocialMidiaSaga);
  yield* takeLatest(SOCIALMIDIA_DELETE_ID.type, asyncDeleteSocialMidia);
}
