import {
  Box,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/storeHook";
import React from "react";

const TermsPage: React.FC = () => {
  const [animationState, setAnimationState] = React.useState(false);
  const dispatch = useAppDispatch();
  const Navigation = useNavigate();

  React.useEffect(() => {
    setAnimationState(true);
    return () => setAnimationState(false);
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "center",
        width: "100%",
        height: "100vh",
        background: (theme) => theme.palette.background.default,
      }}
    >
      <Box sx={{ width: { xs: "90%", sm: "50%" }, margin: "20 auto" }}>
        <Typography variant="h4">TERMOS DE USO E PRIVACIDADE</Typography><br />
        <Typography>O Ministério Apostólico Ramo Estendido (“Ministério”) leva a sério a sua privacidade de dados e sabe que todos se preocupam com a maneira com que suas informações são utilizadas. Por esse motivo, este Termo de Uso e Privacidade explica como coletamos, utilizamos e tratamos os seus dados pessoais durante a visita ao nosso site.</Typography><br />
        <Typography>Todo o tratamento de dados pessoais realizado pelo Ministério está de acordo com os princípios estabelecidos pela LGPD – Lei Geral de Proteção de Dados, que garante aos visitantes uma série de direitos, mas, principalmente, o direito de entender para qual finalidade os seus dados são utilizados. Por isso, podemos afirmar que há a garantia da confidencialidade dos dados pessoais de todos os usuários do site do Ministério Apostólico Ramo Estendido.</Typography><br />
        <Typography>Lembrando que: a informação pessoal recolhida pode incluir o seu nome, e-mail, número de telefone e/ou celular, endereço, data de nascimento e/ou outros por meio do Google Analytics.</Typography><br />
        <Typography>O uso do site do Ministério Apostólico Ramo Estendido pressupõe a aceitação deste Termo de Uso e Privacidade.</Typography><br />
        <Typography variant="h5">QUEM É O CONTROLADOR DOS DADOS COLETADOS EM NOSSO SITE?</Typography><br />
        <Typography>O Ministério Apostólico Ramo Estendido (CNPJ/ME nº 12.983.518/0001-71) “Ministério” é o controlador dos dados coletados em nosso site, tratando-os sempre com atenção às finalidades descritas neste Termo de Uso e Privacidade. Para fins da legislação de proteção de dados aplicável, o controlador é a quem compete as decisões referentes ao tratamento de dados pessoais.</Typography><br />
        <Typography>Os dados pessoais poderão ser compartilhados e/ou coletados por empresas integrantes do Ministério Apostólico Ramo Estendido, hipóteses em que tais empresas também poderão ser controladoras dos dados pessoais.</Typography><br />
        <Typography variant="h5">COMO O MINISTÉRIO COLETA ESSES DADOS PESSOAIS?</Typography><br />
        <Typography>Geralmente, os dados pessoais utilizados pelo Ministério são fornecidos diretamente pelos próprios titulares.</Typography><br />
        <Typography>É essencial que o titular dos dados dê expressamente o seu consentimento para a utilização de seus dados e seja previamente comunicado das finalidades para quais o Ministério pretende utilizar suas informações pessoais.</Typography><br />
        <Typography>Além disso, durante a sua navegação, poderão ser coletados e utilizados Cookies, na forma especificada mais adiante nesta política.</Typography><br />
        <Typography variant="h5">QUAIS SÃO OS DIREITOS DO TITULAR DE DADOS PESSOAIS?</Typography><br />
        <Typography>O titular de dados pessoais tratados pelo Ministério ou por qualquer pessoa física ou jurídica possui os seguintes direitos:</Typography><br />
        <Typography>
          <ul>
            <li>Informação: direito de obter informações claras sobre o tratamento de seus dados.</li>
            <li>Confirmação e acesso aos dados: direito de confirmação da existência de tratamento e, por consequência, o de acessar todos os dados pessoais de sua titularidade que estão sendo coletados e tratados.</li>
            <li>Retificação: direito de corrigir dados incompletos, inexatos ou desatualizados.</li>
            <li>Anonimização, bloqueio ou eliminação: direito de requerer que seus dados sejam anonimizados, bloqueados ou excluídos caso tratados em desconformidade com a LGPD.</li>
            <li>Portabilidade: direito de receber todos os seus dados pessoais fornecidos.</li>
            <li>Revogação do consentimento e eliminação: direito de revogar o consentimento ou se o consentimento do Usuário for retirado.</li>
            <li>Oposição: direito de se opor a quaisquer tratamentos que não estejam em conformidade com a LGPD.</li>
          </ul>
        </Typography><br />
        <Typography variant="h5">QUAL É O COMPORTAMENTO DO TITULAR/USUÁRIO?</Typography><br />
        <Typography>O usuário concorda que é proibido por lei: prejudicar os direitos e interesses de terceiros; inutilizar, modificar ou impedir, em todo ou em parte, qualquer área do site; tentar violar os meios técnicos de proteção ao conteúdo do site; e utilizar o conteúdo do site com finalidade comercial de venda de serviços.</Typography><br />
        <Typography variant="h5">QUEM POSSUI ACESSO AOS DADOS TRATADOS PELO MINISTÉRIO E PARA QUE SÃO UTILIZADOS?</Typography><br />
        <Typography>Em geral, os dados pessoais fornecidos ao Ministério serão utilizados internamente pelos seus colaboradores para a prestação de serviços educacionais e para fins institucionais.</Typography><br />
        <Typography>Contudo, em algumas situações, é possível que o Ministério precise compartilhar seus dados com terceiros, incluindo, mas não se limitando a fornecedores que prestem serviços essenciais ao Ministério, como:</Typography><br />
        <Typography>
          <ul>
            <li>autoridades governamentais;</li>
            <li>consultores (contadores, auditores, advogados);</li>
            <li>fornecedores de ferramentas tecnológicas e softwares necessários à prestação dos nossos serviços; e</li>
            <li>parceiros e empresas contratadas para execução de atividades relacionadas à prestação de nossos serviços.</li>
          </ul>
        </Typography><br />
        <Typography variant="h5">O QUE SÃO COOKIES?</Typography><br />
        <Typography>Como a ampla maioria dos sites, nossa página virtual também utiliza cookies durante a sua navegação.</Typography><br />
        <Typography>Os Cookies são pequenos arquivos com dados que são armazenados pelos navegadores a pedido dos sites, fazendo com que seja possível identificar o dispositivo que está acessando, fazendo com que os sites “lembrem” do dispositivo, tornando possível diversas atividades durante a sua utilização e que ela seja a mais personalizada e fluida possível. No entanto, é importante ressaltar que um cookie não pode ler dados do disco rígido do seu computador. Dessa forma, o uso de cookies permite uma experiência mais rápida e fácil para o usuário.</Typography><br />
        <Typography>Além disso, existem diversos tipos de Cookies com diferentes funções. São as classificações: </Typography><br />
        <Typography>Cookies estritamente necessários (essenciais): são necessários para que o site funcione e não podem ser desligados nos nossos sistemas. Normalmente, eles só são configurados em resposta a ações levadas a cabo por si e que correspondem a uma solicitação de serviços, tais como definir as suas preferências de privacidade, iniciar sessão ou preencher formulários. Pode configurar o seu navegador para bloquear ou alertá-lo sobre esses cookies, mas algumas partes do website não funcionarão. Estes cookies não armazenam qualquer informação pessoal identificável.</Typography><br />
        <Typography>Cookies de desempenho: permitem contar visitas e fontes de tráfego, para que possamos medir e melhorar o desempenho do nosso website. Eles nos ajudam a saber quais são as páginas mais e menos populares e a ver como os visitantes se movimentam pelo website. Todas as informações recolhidas por esses cookies são agregadas e, por conseguinte, anônimas. Se não permitir estes cookies, não saberemos quando visitou o nosso site.</Typography><br />
        <Typography>Cookies de funcionalidade: permitem que o site forneça uma funcionalidade e personalização melhoradas. Podem ser estabelecidos por nós ou por fornecedores externos cujos serviços adicionamos às nossas páginas. Se não permitir estes cookies, algumas destas funcionalidades, ou mesmo todas, podem não atuar corretamente.</Typography><br />
        <Typography>Cookies de publicidade: podem ser estabelecidos através do nosso site pelos nossos parceiros de publicidade. Adicionalmente, podem ser usados por essas empresas para construir um perfil sobre os seus interesses e mostrar-lhe anúncios relevantes em outros websites. Eles não armazenam diretamente informações pessoais, mas são baseados na identificação exclusiva do seu navegador e dispositivo de internet. Se não permitir estes cookies, terá menos publicidade direcionada.</Typography><br />
        <Typography variant="h5">PARA QUE OS COOKIES SÃO UTILIZADOS?</Typography><br />
        <Typography>PARA QUE OS COOKIES SÃO UTILIZADOS?</Typography><br />
        <Typography>
          <ul>
            <li>possibilitar e facilitar a utilização dos nossos sites e de suas funcionalidades;</li>
            <li>diminuir a ocorrência de falhas durante o acesso;</li>
            <li>garantir uma navegação mais personalizável;</li>
            <li>segmentar a publicidade que exibimos; e</li>
            <li>analisar de forma estatística as preferências gerais do conjunto de usuários de nossos sites.</li>
          </ul>
        </Typography><br />
        <Typography variant="h5">NÃO QUERO UTILIZAR COOKIES. O QUE POSSO FAZER?</Typography><br />
        <Typography>Você pode selecionar quais cookies são permitidos nas “Definições de Cookie” da página. Outra forma de bloquear os cookies, é por meio da configuração do seu navegador.</Typography><br />
        <Typography>Além disso, os navegadores permitem que você exclua os cookies que já estejam armazenados em seu computador.</Typography><br />
        <Typography>Caso você desative os cookies necessários, o site pode não funcionar corretamente e as suas funcionalidades também poderão estar parcial ou totalmente indisponíveis.</Typography><br />
        <Typography variant="h5">POR QUANTO TEMPO OS DADOS COLETADOS PERMANECEM ARMAZENADOS?</Typography><br />
        <Typography>O Ministério armazena seus dados pessoais somente pelo tempo necessário para o cumprimento das finalidades para as quais eles foram coletados. Nesse sentido, eles podem ser mantidos:  (a) pelo tempo exigido por obrigações legais ou regulatórias; (b) até o término do tratamento de dados pessoais; ou (c) pelo tempo necessário a preservar o exercício de direitos do Ministério (como, por exemplo, durante prazos prescricionais aplicáveis).</Typography><br />
        <Typography>O término do tratamento de dados ocorrerá quando: (a) for verificado que a finalidade para qual os dados foram coletados foi alcançada e que os dados pessoais coletados deixaram de ser necessários ou pertinentes ao alcance desta finalidade; (b) for revogado o consentimento do Titular dos dados; (c) for finda a relação entre a Ministério e o Titular; ou (d) por determinação legal ou regulatória.</Typography><br />
        <Typography>No caso da revogação de consentimento ou solicitação de exclusão dos seus dados pessoais, que poderá ser solicitada através do Portal de Privacidade disponível em nosso site”, algumas informações, tratadas a partir de outras bases legais que não o consentimento, ainda poderão ser mantidas conforme necessário para, por exemplo, o cumprimento de obrigações legais, exercício regular de direitos, atendimento de interesse legítimo, detecção e prevenção à fraude.</Typography><br />
        <Typography>Após o término de tratamento de dados pessoais, ressalvadas as hipóteses estabelecidas pela legislação aplicável ou pelo presente Termo de Uso e Privacidade, os dados serão eliminados.</Typography><br />
        <Typography variant="h5">CONTATO DO ENCARREGADO</Typography><br />
        <Typography>Se você tiver qualquer dúvida em relação a este Termo de Uso e Privacidade ou a respeito da forma como nós tratamos os seus dados pessoais, acesse nosso Portal de Privacidade, disponível no menu “LGPD” da nossa página inicial.</Typography><br />
        <Typography variant="h5">Mudanças no Termo de Uso e Privacidade</Typography><br />
        <Typography>O Ministério Apostólico Ramo Estendido se esforça para manter seu Termo de Uso e Privacidade atualizado. Toda vez que o Termo for alterado, essas alterações serão divulgadas no site da marca e poderão ser enviadas por e-mail. </Typography><br />
      </Box>
    </Box>
  );
};

export default TermsPage;
