/* eslint-disable @typescript-eslint/no-unused-vars */
import { takeLatest } from "redux-saga/effects";
import { API_METHODS, ResAuth } from "services/api";
import {
  AUTH_SEND_QUEST,
  RESPONSE_SUCCESS_AUTHENTICATION,
  AUTH_CHANGED_STATE,
  VALIDATE_SEND_QUEST,
} from "store/reducers/auth/auth.reducer";
import { all, call, take, cancel, delay, put } from "typed-redux-saga";

function* authorize(payload: { email: string; password: string }) {
  //console.log("AUTHORIZE");
  try {
    yield put(
      AUTH_CHANGED_STATE({
        loading: true,
      })
    );
    //yield delay(3000);
    const res = yield* call(API_METHODS.AUTHENTICATION, payload);
    //console.log(`auth=res`);
    if (res.data.token) {
      yield* put(
        RESPONSE_SUCCESS_AUTHENTICATION({
          user: {
            token: res.data.token,
            user: res.data.user,
          },
        })
      );
      yield console.log("RESPOSTA SERVER", res.data.user);
      return true;
    }
  } catch (e) {
    AUTH_CHANGED_STATE({
      loading: false,
      error: true,
      msgError: "Usuário ou senha invalidos!",
    });
  } finally {
    yield put(
      AUTH_CHANGED_STATE({
        loading: false,
      })
    );
    //console.log("FINALIZOU");
  }
}
function* validate(payload: { token: string; id: number }) {
  //console.log("VALIDATE");
  try {
    yield put(
      AUTH_CHANGED_STATE({
        loading: true,
      })
    );
    //yield delay(3000);
    const token = payload.token;
    const res = yield* call(API_METHODS.VALIDATE, payload);
    //console.log(`validate=res`);
    // só atualizar o token caso necessário
    if ((res.data.token) && (res.data.token !== token)) {
      yield* put(
        RESPONSE_SUCCESS_AUTHENTICATION({
          user: {
            token: res.data.token,
            user: res.data.user,
          },
        })
      );
      yield console.log("RESPOSTA SERVER", res.data.user);
      return true;
    }
  } catch (e) {
    AUTH_CHANGED_STATE({
      loading: false,
      error: true,
      msgError: "Usuário ou senha invalidos!",
    });
  } finally {
    yield put(
      AUTH_CHANGED_STATE({
        loading: false,
      })
    );
    //console.log("FINALIZOU");
  }
}
export function* authFlow() {
  while (true) {
    const auth = yield* take(AUTH_SEND_QUEST.type) as any;
    const autoLoopAuth = yield* call(authorize, auth.payload);
    if (!autoLoopAuth) {
      yield* cancel(autoLoopAuth);
    }
  }
}

export function* validateFlow() {
  while (true) {
    const valid = yield* take(VALIDATE_SEND_QUEST.type) as any;
    const autoLoopValidate = yield* call(validate, valid.payload);
    if (!autoLoopValidate) {
      yield* cancel(autoLoopValidate);
    }
  }
}