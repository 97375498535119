import { AnyAction, createAction, createReducer } from "@reduxjs/toolkit";

export const SOCIALMIDIA_ASYNC_ALL = createAction("SOCIALMIDIA_ASYNC_ALL");

export const SOCIALMIDIA_CREATE_PAYLOAD = createAction<{ [key: string]: any }>(
  "SOCIALMIDIA_CREATE_PAYLOAD"
);
export const SOCIALMIDIA_CREATE_SUCCESS = createAction<{ msg: string }>(
  "SOCIALMIDIA_CREATE_SUCCESS"
);
export const SOCIALMIDIA_CREATE_ERROR =
  createAction<{ msg: string }>("SOCIALMIDIA_CREATE_ERROR");

export const SOCIALMIDIA_CHANGED_EVENTS = createAction<{ [key: string]: any }>(
  "SOCIALMIDIA_CHANGED_EVENTS"
);

export const SOCIALMIDIA_LOADING_SUCCESS = createAction<{
  data: Array<Partial<any>>;
}>("SOCIALMIDIA_LOADING_SUCCESS");

export const SOCIALMIDIA_DELETE_ID = createAction<{ id: any }>(
  "SOCIALMIDIA_DELETE_ID"
);

const initalState: {
  id: number;
  loading: boolean;
  success: boolean | null;
  error: boolean | null;
  msg: string | null;
  result: Array<Partial<any>>;
  socialMidias: Array<Partial<any>>;
  count: number;
  page: number;
  pageSize: number;
  search: string;
  socialMidia: Partial<any>;
} = {
  id: 0,
  loading: false,
  success: null,
  error: null,
  msg: null,
  result: [],
  socialMidias: [],
  count: 0,
  page: 0,
  pageSize: 6,
  search: "",
  socialMidia: {},
};
const notificationsReducer = createReducer(initalState, (build) => {
  build
    .addCase(SOCIALMIDIA_CHANGED_EVENTS.type, (state, action: AnyAction) => {
      return {
        ...state,
        ...action.payload,
      };
    })
    .addCase(SOCIALMIDIA_CREATE_SUCCESS.type, (state, action: AnyAction) => {
      state.error = false;
      state.success = true;
      state.result = action.payload;
    })
    .addCase(SOCIALMIDIA_CREATE_ERROR.type, (state, action: AnyAction) => {
      state.error = false;
      state.success = false;
      state.msg = action.payload;
    })
    .addCase(SOCIALMIDIA_LOADING_SUCCESS.type, (state, action: AnyAction) => {
      return {
        ...state,
        socialMidia: action.payload,
        //count: action.payload.count,
      };
    });
});

export default notificationsReducer;
