import { all, fork } from "typed-redux-saga";
import { authFlow, validateFlow } from "./auth/auth.saga";
import { feedsSaga, feedCreateSaga } from "./feeds/feeds.saga";
import { usersFlow, userCreateFlow } from "./users/users.saga";
import dashFlow from "./dashboard/dash.saga";
import eventsSaga from "./events/events.saga";
import { notificationSaga, notificationsSaga } from "./notifications/notifications.saga";
import { trainingsSaga, trainingCreateSaga, trainingDeleteSaga, trainingExcelSaga } from "./trainings/trainings.saga";
import { socialMidiasSaga, socialMidiaSaga } from "./socialmidias/socialmidias.saga";

export default function* rootSaga() {
  yield* all([
    fork(authFlow),
    fork(validateFlow),
    fork(usersFlow),
    fork(userCreateFlow),
    fork(feedsSaga),
    fork(feedCreateSaga),
    fork(dashFlow),
    fork(eventsSaga),
    fork(notificationSaga),
    fork(notificationsSaga),
    fork(trainingsSaga),
    fork(trainingCreateSaga),
    fork(trainingDeleteSaga),
    fork(trainingExcelSaga),
    fork(socialMidiasSaga),
    fork(socialMidiaSaga),
  ]);
}
