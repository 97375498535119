import { AnyAction, createAction, createReducer } from "@reduxjs/toolkit";

export const EVENT_CREATE_PAYLOAD = createAction<{ [key: string]: any }>(
  "EVENT_CREATE_PAYLOAD"
);
export const EVENT_CREATE_SUCCESS = createAction<{ msg: string }>(
  "EVENT_CREATE_SUCCESS"
);
export const EVENT_CREATE_ERROR =
  createAction<{ msg: string }>("EVENT_CREATE_ERROR");
export const EVENT_ASYNC_ALL = createAction("EVENT_ASYNC_ALL");
export const EVENT_CHANGED_EVENTS = createAction<{ [key: string]: any }>(
  "EVENT_CHANGED_EVENTS"
);
export const EVENT_LOADING_SUCCESS = createAction<{
  data: Array<Partial<any>>;
}>("EVENT_LOADING_SUCCESS");
export const EVENT_SET_TO_STORE =
  createAction<{ [key: string]: any }>("EVENT_SET_TO_STORE");
export const EVENT_DELETE_ID = createAction<{ id: any }>(
  "EVENT_DELETE_ID"
);
const initalState: {
  id: number;
  loading: boolean;
  events: Array<Partial<any>>;
  success: boolean | null;
  error: boolean | null;
  msgUser: string | null;
  count: number;
  page: number;
  pageSize: number;
  search: string;
} = {
  id: 0,
  loading: false,
  events: [],
  success: null,
  error: null,
  msgUser: null,
  count: 0,
  page: 0,
  pageSize: 6,
  search: "",
};
const eventsReducer = createReducer(initalState, (build) => {
  build
    .addCase(EVENT_CHANGED_EVENTS.type, (state, action: AnyAction) => {
      return {
        ...state,
        ...action.payload,
      };
    })
    .addCase(EVENT_LOADING_SUCCESS.type, (state, action: AnyAction) => {
      return {
        ...state,
        events: action.payload.data,
        count: action.payload.count,
      };
    })
    .addCase(EVENT_CREATE_SUCCESS.type, (state, action: AnyAction) => {
      state.error = false;
      state.success = true;
      state.events = action.payload;
    })
    .addCase(EVENT_CREATE_ERROR.type, (state, action: AnyAction) => {
      state.error = false;
      state.success = false;
      state.msgUser = action.payload;
    });
});

export default eventsReducer;
