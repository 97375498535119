import React from "react";
import { Event, Feed, Person, School } from "@mui/icons-material";
import { Box, Paper, Stack, Typography, Slide, Backdrop } from "@mui/material";
import { green, lightGreen } from "@mui/material/colors";
import { useAppDispatch, useAppSelector } from "hooks/storeHook";
import CircularProgress from "@mui/material/CircularProgress";
import { DASH_ASYNC } from "store/reducers/dashboard/dash.reducer";

const DashBoardPage = function DashBoardPage(): JSX.Element {
  const [animationState, setAnimationState] = React.useState(false);
  const dashState = useAppSelector((state) => state.dash);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    setAnimationState(true);
    return () => setAnimationState(false);
  }, []);

  React.useEffect(() => {
    dispatch(DASH_ASYNC());
  }, []);

  return (
    <Slide direction="up" in={animationState} mountOnEnter unmountOnExit>
      <Box
        sx={{
          width: "95%",
          margin: "0 auto",
          height: "70vh",
          mt: { xs: 5, sm: 0 },
        }}
      >
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={2}
          justifyContent="space-between"
          sx={{ width: { xs: "100%", sm: "100%" } }}
        >
          <Box
            component={Paper}
            sx={{
              height: "120px",
              backgroundColor: lightGreen["500"],
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: {
                xs: "100%",
                sm: "33.33%",
              },
            }}
          >
            <Stack
              direction="row"
              justifyContent="space-around"
              alignContent="center"
              sx={{ width: "95%", margin: "0 auto" }}
            >
              <Box
                sx={{ display: "flex", flexDirection: "column" }}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Person sx={{ color: "white", fontSize: "3.5em" }} />
              </Box>
              <Box
                sx={{ display: "flex", flexDirection: "column" }}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Typography color="white" variant="h6">
                  Usuários
                </Typography>
                <Typography color="white" variant="h4">
                  {dashState.dashboard?.users}
                </Typography>
              </Box>
            </Stack>
          </Box>
          {/*<Box
            component={Paper}
            sx={{
              height: "120px",
              backgroundColor: lightGreen["500"],
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: {
                xs: "100%",
                sm: "33.33%",
              },
            }}
          >
            <Stack
              direction="row"
              justifyContent="space-around"
              alignContent="center"
              sx={{ width: "95%", margin: "0 auto" }}
            >
              <Box
                sx={{ display: "flex", flexDirection: "column" }}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Event sx={{ color: "white", fontSize: "3.5em" }} />
              </Box>
              <Box
                sx={{ display: "flex", flexDirection: "column" }}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Typography color="white" variant="h6">
                  Eventos
                </Typography>
                <Typography color="white" variant="h4">
                  {dashState.dashboard?.events}
                </Typography>
              </Box>
            </Stack>
          </Box>*/}
          <Box
            component={Paper}
            sx={{
              height: "120px",
              backgroundColor: lightGreen["500"],
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: {
                xs: "100%",
                sm: "33.33%",
              },
            }}
          >
            <Stack
              direction="row"
              justifyContent="space-around"
              alignContent="center"
              sx={{ width: "95%", margin: "0 auto" }}
            >
              <Box
                sx={{ display: "flex", flexDirection: "column" }}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Feed sx={{ color: "white", fontSize: "3.5em" }} />
              </Box>
              <Box
                sx={{ display: "flex", flexDirection: "column" }}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Typography color="white" variant="h6">
                  Feeds
                </Typography>
                <Typography color="white" variant="h4">
                  {dashState.dashboard?.feeds}
                </Typography>
              </Box>
            </Stack>
          </Box>
          <Box
            component={Paper}
            sx={{
              height: "120px",
              backgroundColor: lightGreen["500"],
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: {
                xs: "100%",
                sm: "33.33%",
              },
            }}
          >
            <Stack
              direction="row"
              justifyContent="space-around"
              alignContent="center"
              sx={{ width: "95%", margin: "0 auto" }}
            >
              <Box
                sx={{ display: "flex", flexDirection: "column" }}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <School sx={{ color: "white", fontSize: "3.5em" }} />
              </Box>
              <Box
                sx={{ display: "flex", flexDirection: "column" }}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Typography color="white" variant="h6">
                  Eventos
                </Typography>
                <Typography color="white" variant="h4">
                  {dashState.dashboard?.trainings}
                </Typography>
              </Box>
            </Stack>
          </Box>
        </Stack>
        {dashState.loading && (
          <Backdrop
            sx={{
              bgColor: "#3333",
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={dashState.loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </Box>
    </Slide>
  );
};
export default DashBoardPage;
