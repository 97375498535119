/* eslint-disable no-template-curly-in-string */
/* eslint-disable @typescript-eslint/no-redeclare */
import React from "react";
import {
  Collapse,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  List,
} from "@mui/material";
import { Link } from "react-router-dom";
import {
  Dashboard,
  Person,
  Feed,
  School,
  Event,
  ExpandLess,
  ExpandMore,
  RemoveRedEye,
  Add as AddIcon,
  NotificationAdd,
  Instagram,
} from "@mui/icons-material";
const ListDrawer: React.FC = () => {
  const [open, setOpen] = React.useState<{ [key: string]: boolean }>({
    operadores: false,
    treinamentos: false,
    feeds: false,
    events: false,
    notifications: false,
  });
  const handleClick = (target: any) => {
    setOpen({
      ...open,
      [target]: !open[target],
    });
  };
  return (
    <List>
      <ListItem disablePadding>
        <ListItemButton component={Link} to="dashboard">
          <ListItemIcon>
            <Dashboard sx={{ color: "HighlightText" }} />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton onClick={() => handleClick("operadores")}>
          <ListItemIcon>
            <Person sx={{ color: "HighlightText" }} />
          </ListItemIcon>
          <ListItemText primary="Operadores" />
          {open.operadores ? (
            <ExpandLess sx={{ color: "HighlightText" }} />
          ) : (
            <ExpandMore sx={{ color: "HighlightText" }} />
          )}
        </ListItemButton>
      </ListItem>
      <Collapse in={open.operadores} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }} component={Link} to="operators/new">
            <ListItemIcon>
              <AddIcon sx={{ color: "white" }} />
            </ListItemIcon>
            <ListItemText primary="cadastrar" />
          </ListItemButton>
        </List>
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }} component={Link} to="operators/list">
            <ListItemIcon>
              <RemoveRedEye sx={{ color: "white" }} />
            </ListItemIcon>
            <ListItemText primary="operadores" />
          </ListItemButton>
        </List>
      </Collapse>
      {/*<ListItem disablePadding>
        <ListItemButton onClick={() => handleClick("events")}>
          <ListItemIcon>
            <Event sx={{ color: "white" }} />
          </ListItemIcon>
          <ListItemText primary="Eventos" />
          {open.events ? (
            <ExpandLess sx={{ color: "white" }} />
          ) : (
            <ExpandMore sx={{ color: "white" }} />
          )}
        </ListItemButton>
      </ListItem>
      <Collapse in={open.events} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }} component={Link} to="eventos/new">
            <ListItemIcon>
              <AddIcon sx={{ color: "HighlightText" }} />
            </ListItemIcon>
            <ListItemText primary="cadastrar" />
          </ListItemButton>
        </List>
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }} component={Link} to="eventos/list">
            <ListItemIcon>
              <RemoveRedEye sx={{ color: "HighlightText" }} />
            </ListItemIcon>
            <ListItemText primary="eventos" />
          </ListItemButton>
        </List>
          </Collapse>*/}
      <ListItem disablePadding>
        <ListItemButton onClick={() => handleClick("feeds")}>
          <ListItemIcon>
            <Feed sx={{ color: "white" }} />
          </ListItemIcon>
          <ListItemText primary="Feeds" />
          {open.feeds ? (
            <ExpandLess sx={{ color: "white" }} />
          ) : (
            <ExpandMore sx={{ color: "white" }} />
          )}
        </ListItemButton>
      </ListItem>
      <Collapse in={open.feeds} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }} component={Link} to="feeds/new">
            <ListItemIcon>
              <AddIcon sx={{ color: "HighlightText" }} />
            </ListItemIcon>
            <ListItemText primary="cadastrar" />
          </ListItemButton>
        </List>
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }} component={Link} to="feeds/list">
            <ListItemIcon>
              <RemoveRedEye sx={{ color: "HighlightText" }} />
            </ListItemIcon>
            <ListItemText primary="feeds" />
          </ListItemButton>
        </List>
      </Collapse>
      <ListItem disablePadding>
        <ListItemButton onClick={() => handleClick("treinamentos")}>
          <ListItemIcon>
            <School sx={{ color: "white" }} />
          </ListItemIcon>
          <ListItemText primary="Eventos" />
          {open.treinamentos ? (
            <ExpandLess sx={{ color: "white" }} />
          ) : (
            <ExpandMore sx={{ color: "white" }} />
          )}
        </ListItemButton>
      </ListItem>
      <Collapse in={open.treinamentos} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }} component={Link} to="events/new">
            <ListItemIcon>
              <AddIcon sx={{ color: "HighlightText" }} />
            </ListItemIcon>
            <ListItemText primary="cadastrar" />
          </ListItemButton>
        </List>
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }} component={Link} to="events/list">
            <ListItemIcon>
              <RemoveRedEye sx={{ color: "HighlightText" }} />
            </ListItemIcon>
            <ListItemText primary="Eventos" />
          </ListItemButton>
        </List>
      </Collapse>
      <ListItem disablePadding>
        <ListItemButton onClick={() => handleClick("notifications")}>
          <ListItemIcon>
            <NotificationAdd sx={{ color: "white" }} />
          </ListItemIcon>
          <ListItemText primary="Notificações" />
          {open.notifications ? (
            <ExpandLess sx={{ color: "white" }} />
          ) : (
            <ExpandMore sx={{ color: "white" }} />
          )}
        </ListItemButton>
      </ListItem>
      <Collapse in={open.notifications} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }} component={Link} to="notifications/new">
            <ListItemIcon>
              <AddIcon sx={{ color: "HighlightText" }} />
            </ListItemIcon>
            <ListItemText primary="cadastrar" />
          </ListItemButton>
        </List>
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }} component={Link} to="notifications/list">
            <ListItemIcon>
              <RemoveRedEye sx={{ color: "HighlightText" }} />
            </ListItemIcon>
            <ListItemText primary="Notificações" />
          </ListItemButton>
        </List>
      </Collapse>
      <ListItem disablePadding>
        <ListItemButton component={Link} to="socialmidias">
          <ListItemIcon>
            <Instagram sx={{ color: "HighlightText" }} />
          </ListItemIcon>
          <ListItemText primary="Mídias Social" />
        </ListItemButton>
      </ListItem>
      {/*<ListItem disablePadding>
        <ListItemButton component={Link} to="notification">
          <ListItemIcon>
            <NotificationAdd sx={{ color: "HighlightText" }} />
          </ListItemIcon>
          <ListItemText primary="Notificação" />
        </ListItemButton>
          </ListItem>*/}
      {/*       <ListItem disablePadding>
        <ListItemButton>
          <ListItemIcon>
            <School sx={{ color: "white" }} />
          </ListItemIcon>
          <ListItemText primary="Treinamentos" />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton>
          <ListItemIcon>
            <Event sx={{ color: "white" }} />
          </ListItemIcon>
          <ListItemText primary="Eventos" />
        </ListItemButton>
      </ListItem> */}
    </List>
  );
};

export default ListDrawer;
