/* eslint-disable @typescript-eslint/no-unused-expressions */
import { AnyMap } from "immer/dist/internal";
import { all } from "redux-saga/effects";
import { API_METHODS } from "services/api";
import {
  TRAINING_ASYNC_ALL,
  TRAINING_CREATE_PAYLOAD,
  TRAINING_CHANGED_EVENTS,
  TRAINING_LOADING_SUCCESS,
  TRAINING_CREATE_SUCCESS,
  TRAINING_CREATE_ERROR,
  TRAINING_DELETE_ID,
  TRAINING_EXCEL,
} from "store/reducers/trainings/trainings.reducer";
import { takeLatest, fork, take, select, call, put, cancel, delay } from "typed-redux-saga";
import fileDownload from 'js-file-download';
import { request } from "http";

function* getTrainings() {
  try {
    yield put(
      TRAINING_CHANGED_EVENTS({
        loading: true,
        success: false,
        error: false,
        trainings: [],
      })
    );
    const token = yield* select((state) => state.auth.auth.token);
    const trainings = yield* select((state) => state.trainings);
    const Request = yield* call(API_METHODS.GET_ALL_TRAININGS, token, trainings.page, trainings.pageSize, trainings.search);
    yield put(TRAINING_LOADING_SUCCESS(Request?.data));
    return Request;
  } catch (error) {
    yield put(
      TRAINING_CHANGED_EVENTS({
        loading: false,
        success: false,
        error: true,
      })
    );
    return error;
  } finally {
    yield put(
      TRAINING_CHANGED_EVENTS({
        loading: false,
      })
    );
  }
}
function* getAllTrainingsFoork() {
  yield* call(getTrainings);
}

function* getExcel(action: any) {
  try {
    yield put(
      TRAINING_CHANGED_EVENTS({
        loading: true,
      })
    );
    const token = yield* select((state) => state.auth.auth.token);
    const Request = yield* call(API_METHODS.EXCEL_A_TRAINING, token, action.payload.id);
    //yield put(TRAINING_LOADING_SUCCESS(Request?.data));

    console.log(Request);
    window.open(Request.data.url);

    return Request;
  } catch (error) {
    yield put(
      TRAINING_CHANGED_EVENTS({
        loading: false,
        success: false,
        error: true,
      })
    );
    return error;
  } finally {
    yield put(
      TRAINING_CHANGED_EVENTS({
        loading: false,
      })
    );
  }
}

function* uploadQuestionImage(token: string, question: any, resultQuestion: any, imageQuestion: any) {
  if (question.name === resultQuestion.name) {
    var exists = false;
    //console.log(resultQuestion.id);
    const imageQuestionUpload = new FormData();
    Array.from(imageQuestion).map((img: any) => {
      if (img !== undefined && img !== null) {
        exists = true;
      }
      imageQuestionUpload.append("image", img);
    });
    //console.log(imageQuestionUpload)
    if (exists) {
      const response = yield* call(
        API_METHODS.UPLOAD_A_QUESTIONS,
        token,
        resultQuestion.id,
        imageQuestionUpload
      );
      return response.data;
    } else {
      return { status: "not found" }
    }
  }
}
function* processQuestionImage(token: string, resultQuestions: any[], question: any) {
  const { imageQuestion, ...normalizeQuestion } = question;
  //console.log(question);
  if (imageQuestion !== undefined) {
    //console.log(imageQuestion);
    yield* resultQuestions.map((resultQuestion: any) => uploadQuestionImage(token, normalizeQuestion, resultQuestion, imageQuestion));
  }
  return {
    ok: "ok"
  }
}
function* createTrainingSaga(action: any) {
  //console.log("createTrainingSaga");
  const token = yield* select((state) => state.auth.auth.token);
  const { id, image, imageUrl, questions, ...normalize } = action.payload;
  const normalizeQuestions: any[] = [];
  questions.map((question: any) => {
    const { imageQuestion, imageUrl, ...normalizeQuestion } = question;
    normalizeQuestions.push(normalizeQuestion);
  });
  normalize.questions = normalizeQuestions;
  //console.log(normalize);
  var result;
  try {
    yield* put(TRAINING_CHANGED_EVENTS({ loading: true, success: false, error: false }));
    if (id !== undefined && id > 0) {
      result = yield* call(API_METHODS.UPDATE_A_TRAININGS, token, id, normalize);
    } else {
      result = yield* call(API_METHODS.CREATE_A_TRAININGS, token, normalize);
    }
    //console.log(result);
    if (result.error) {
      yield* put(
        TRAINING_CHANGED_EVENTS({
          loading: false,
          error: true,
          msg: `${result.error.data}`,
        })
      );
      return;
    }
    if (result && result.data && result.data.id) {
      const resultQuestions = result.data.questions;
      //console.log(resultQuestions);

      if (image !== undefined) {
        var exists = false;
        const imageUpload = new FormData();
        Array.from(image).map((img: any) => {
          if (img !== undefined && img !== null) {
            exists = true;
          }
          imageUpload.append("image", img);
        });
        if (exists) {
          yield* call(
            API_METHODS.UPLOAD_A_TRAININGS,
            token,
            result.data.id,
            imageUpload
          );
        }
      }

      if (resultQuestions) {
        //yield* delay(2000);
        yield* questions.map((question: any) => processQuestionImage(token, resultQuestions, question));
        //yield* delay(3000);
      }

      yield* put(TRAINING_CHANGED_EVENTS({ loading: false, success: true, error: false }));
    }
  } catch (error) {
    //console.log("ERRO");
    console.log(error);
    //const e: any = error;
    yield* put(
      TRAINING_CHANGED_EVENTS({
        loading: false,
        error: true,
        msg: `${error}`,
      })
    );
  } finally {
    yield* put(TRAINING_CHANGED_EVENTS({ loading: false }));
  }
}
function* asyncDeleteTraining(action: any) {
  const { payload } = action;
  yield put(
    TRAINING_CHANGED_EVENTS({
      loading: true,
      success: false,
      error: false,
      trainings: [],
    })
  );
  //console.log(`asyncDeleteEvent`);
  //console.log(payload);
  const { id } = payload;
  const token = yield* select((state) => state.auth.auth.token);
  try {
    let Request;
    if ((id) && (id !== 0)) {
      //console.log(id);
      Request = yield* call(API_METHODS.DELETE_A_TRAININGS, token, id);
      //yield* delay(2000);
    }
    //console.log(Request);
    //yield* put(TRAINING_CREATE_SUCCESS({ msg: "Treinamento deletado corretamente" }));
    yield* put(
      TRAINING_CHANGED_EVENTS({
        loading: false,
        success: true,
        error: false,
      })
    );
    return Request;
  } catch (error) {
    /*yield* put(
      TRAINING_CREATE_ERROR({ msg: "Ocorreu um erro ao deletar o treinamento" })
    );*/
    yield* put(
      TRAINING_CHANGED_EVENTS({
        loading: false,
        success: false,
        error: true,
        msg: "Ocorreu um erro ao deletar o treinamento"
      })
    );
  } finally {
    //yield console.log("FINALLY");
    yield* put(
      TRAINING_CHANGED_EVENTS({
        loading: false,
        trainings: [],
      })
    );
  }
}
export function* trainingsSaga() {
  while (true) {
    yield* take([TRAINING_ASYNC_ALL.type]);
    const asyncAll = yield* fork(getAllTrainingsFoork);
    if (!asyncAll) {
      yield* cancel(asyncAll);
    }
    yield* takeLatest(TRAINING_DELETE_ID.type, asyncDeleteTraining);
  }
}
export function* trainingCreateSaga() {
  yield* takeLatest(TRAINING_CREATE_PAYLOAD.type, createTrainingSaga);
}
export function* trainingDeleteSaga() {

}
export function* trainingExcelSaga() {
  yield* takeLatest(TRAINING_EXCEL.type, getExcel);
}