import axios from "axios";

export const apiUrl = "https://app.ramoestendido.com.br/api/";
//export const apiUrl = "http://localhost:4200/api/";
//export const apiUrl = "http://localhost:3000/api/";

const api = axios.create({
  baseURL: apiUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

/* authentication */

export type ResAuth = {
  token: string;
  user: {
    id: string | number;
    name: string;
    email: string;
    userName: string;
    birthDate: string;
    imageUrl: string;
  };
};

export type ResDash = {
  users: number,
  feeds: number,
  trainings: number,
  events: number,
};

const AUTHENTICATION = async (payload: { email: string; password: string }) => {
  const response = await api.get<{ data: ResAuth }>("auth", {
    headers: {
      Authorization: `Base64 ${window.btoa(
        `${payload.email}:${payload.password}`
      )}`,
    },
  });
  return response.data;
};

const VALIDATE = async (payload: { token: string, id: number }) => {
  const response = await api.get<{ data: ResAuth }>(`validate?id=${payload.id}`, {
    headers: { Authorization: `Bearer ${payload.token}` },
  });
  return response.data;
}

/* DASHBOARD ROUTES */
const GET_ALL_DASHBOARD = async (token: string) =>
  await (
    await api.get<any>(`dashboard/initial`, {
      headers: { Authorization: `Bearer ${token}` },
    })
  ).data;

/* USER ROUTES */
const GET_ALL_USERS = async (token: string, page: number, pageSize: number, search: string) => {
  var _search = "";
  if (search !== undefined && search !== "") {
    _search = `&_search=${search}`;
  }
  return await (
    await api.get<any>(`users?type=admin,operator&_page=${page}&_total=${pageSize}${_search}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
  ).data;
}
const GET_SINGLE_USER = async (token: string, id: string | number) =>
  await (
    await api.get<any>(`user/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
  ).data;
const CREATE_A_USER = async (token: string, payload: any) =>
  await (
    await api.post<any>(`user`, JSON.stringify(payload), {
      headers: { Authorization: `Bearer ${token}` },
    })
  ).data;

const UPDATE_A_USER = async (
  token: string,
  id: string | number,
  payload: any
) =>
  await (
    await api.patch<any>(`user/${id}`, JSON.stringify(payload), {
      headers: { Authorization: `Bearer ${token}` },
    })
  ).data;

const DELETE_A_USER = async (token: string, id: string | number) =>
  await (
    await api.delete<any>(`user/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
  ).data;

/* FEEDS /feed-new */
const GET_ALL_FEEDS = async (token: string, page: number, pageSize: number, search: string) => {
  var _search = "";
  if (search !== undefined && search !== "") {
    _search = `&_search=${search}`;
  }
  return await (
    await api.get<any>(`feed-news?_page=${page}&_total=${pageSize}${_search}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
  ).data;
}
const CREATE_A_FEEDS = async (token: string, payload: any) =>
  await (
    await api.post<any>(`/feed-new`, JSON.stringify(payload), {
      headers: { Authorization: `Bearer ${token}` },
    })
  ).data;
const UPDATE_A_FEEDS = async (
  token: string,
  id: string | number,
  payload: any
) =>
  await (
    await api.patch<any>(`/feed-new/${id}`, JSON.stringify(payload), {
      headers: { Authorization: `Bearer ${token}` },
    })
  ).data;
const DELETE_A_FEEDS = async (token: string, id: string | number) =>
  await (
    await api.delete<any>(`/feed-new/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
  ).data;
const UPLOAD_A_FEEDS = async (token: string, id: string | number, image: any) =>
  await (
    await api.post<any>(`/feed-new/${id}/image`, image, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    })
  ).data;

/* EVENTS /event */
const GET_ALL_EVENTS = async (token: string, page: number, pageSize: number, search: string) => {
  var _search = "";
  if (search !== undefined && search !== "") {
    _search = `&_search=${search}`;
  }
  return await (
    await api.get<any>(`events?_page=${page}&_total=${pageSize}${_search}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
  ).data;
}
const CREATE_A_EVENTS = async (token: string, payload: any) =>
  await (
    await api.post<any>(`/event`, JSON.stringify(payload), {
      headers: { Authorization: `Bearer ${token}` },
    })
  ).data;
const UPDATE_A_EVENTS = async (
  token: string,
  id: string | number,
  payload: any
) =>
  await (
    await api.patch<any>(`/event/${id}`, JSON.stringify(payload), {
      headers: { Authorization: `Bearer ${token}` },
    })
  ).data;
const DELETE_A_EVENTS = async (token: string, id: string | number) =>
  await (
    await api.delete<any>(`/event/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
  ).data;
const UPLOAD_A_EVENTS = async (token: string, id: string | number, image: any) =>
  await (
    await api.post<any>(`/event/${id}/image`, image, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    })
  ).data;

/* TRAININGS /training */
const GET_ALL_TRAININGS = async (token: string, page: number, pageSize: number, search: string) => {
  var _search = "";
  if (search !== undefined && search !== "") {
    _search = `&_search=${search}`;
  }
  return await (
    await api.get<any>(`trainings?_page=${page}&_total=${pageSize}${_search}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
  ).data;
}
const CREATE_A_TRAININGS = async (token: string, payload: any) => {
  const response = await api.post<any>(`/training`, JSON.stringify(payload), {
    headers: { Authorization: `Bearer ${token}` },
  }).catch(function (error) {
    if (error.response) {
      //console.log(error.response.data);
      //console.log(error.response.status);
      //console.log(error.response.headers);
      const data = error.response.data;
      const isArr = Object.prototype.toString.call(data) === '[object Array]';
      if (isArr) {
        const list = data as any[];
        //console.log(list);
        return { data: { error: list.join(", ") } };
      }
      return { data: { error: error.response.data } };
    } else if (error.request) {
      console.log(error.request);
      return { data: { error: error.request } };
    } else {
      console.log('Error', error.message);
      return error.message;
    }
  });
  return response.data;
}
const UPDATE_A_TRAININGS = async (
  token: string,
  id: string | number,
  payload: any
) =>
  await (
    await api.patch<any>(`/training/${id}`, JSON.stringify(payload), {
      headers: { Authorization: `Bearer ${token}` },
    })
  ).data;
const EXCEL_A_TRAINING = async (token: string, id: string | number) =>
  await (
    await api.get<any>(`/training/${id}/excel`, {
      headers: { Authorization: `Bearer ${token}` },
    })
  ).data;
const DELETE_A_TRAININGS = async (token: string, id: string | number) =>
  await (
    await api.delete<any>(`/training/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
  ).data;
const UPLOAD_A_TRAININGS = async (token: string, id: string | number, image: any) =>
  await (
    await api.post<any>(`/training/${id}/image`, image, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    })
  ).data;
const UPLOAD_A_QUESTIONS = async (token: string, id: string | number, image: any) =>
  await (
    await api.post<any>(`/question/${id}/image`, image, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    })
  ).data;

/* NOTIFICATIONS /notification */
const GET_ALL_NOTIFICATIONS = async (token: string, page: number, pageSize: number, search: string) => {
  var _search = "";
  if (search !== undefined && search !== "") {
    _search = `&_search=${search}`;
  }
  return await (
    await api.get<any>(`notifications?_page=${page}&_total=${pageSize}${_search}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
  ).data;
}
const CREATE_A_NOTIFICATION = async (token: string, payload: any) => {
  //console.log(payload);
  const response =
    await api.post<any>(`notification`, JSON.stringify(payload), {
      headers: { Authorization: `Bearer ${token}` },
    });
  //console.log(response.data);
  return response.data;
}
const UPDATE_A_NOTIFICATION = async (
  token: string,
  id: string | number,
  payload: any
) =>
  await (
    await api.patch<any>(`/notification/${id}`, JSON.stringify(payload), {
      headers: { Authorization: `Bearer ${token}` },
    })
  ).data;
const DELETE_A_NOTIFICATION = async (token: string, id: string | number) =>
  await (
    await api.delete<any>(`/notification/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
  ).data;

/* SOCIALMIDIA /social midia */
const GET_ALL_SOCIALMIDIA = async (token: string, page: number, pageSize: number, search: string) => {
  var _search = "";
  if (search !== undefined && search !== "") {
    _search = `&_search=${search}`;
  }
  return await (
    await api.get<any>(`social-midia/1`, {
      headers: { Authorization: `Bearer ${token}` },
    })
  ).data;
}
const CREATE_A_SOCIALMIDIA = async (token: string, payload: any) => {
  //console.log(payload);
  const response =
    await api.post<any>(`social-midia`, JSON.stringify(payload), {
      headers: { Authorization: `Bearer ${token}` },
    });
  //console.log(response.data);
  return response.data;
}
const UPDATE_A_SOCIALMIDIA = async (
  token: string,
  id: string | number,
  payload: any
) =>
  await (
    await api.patch<any>(`/social-midia/1`, JSON.stringify(payload), {
      headers: { Authorization: `Bearer ${token}` },
    })
  ).data;
const DELETE_A_SOCIALMIDIA = async (token: string, id: string | number) =>
  await (
    await api.delete<any>(`/social-midia/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
  ).data;

export const API_METHODS = {
  AUTHENTICATION,
  VALIDATE,
  GET_ALL_DASHBOARD,
  GET_ALL_USERS,
  GET_SINGLE_USER,
  CREATE_A_USER,
  UPDATE_A_USER,
  DELETE_A_USER,
  GET_ALL_FEEDS,
  CREATE_A_FEEDS,
  UPLOAD_A_FEEDS,
  UPDATE_A_FEEDS,
  DELETE_A_FEEDS,
  GET_ALL_EVENTS,
  CREATE_A_EVENTS,
  UPLOAD_A_EVENTS,
  UPDATE_A_EVENTS,
  DELETE_A_EVENTS,
  GET_ALL_TRAININGS,
  CREATE_A_TRAININGS,
  UPLOAD_A_TRAININGS,
  UPLOAD_A_QUESTIONS,
  UPDATE_A_TRAININGS,
  DELETE_A_TRAININGS,
  GET_ALL_NOTIFICATIONS,
  CREATE_A_NOTIFICATION,
  UPDATE_A_NOTIFICATION,
  DELETE_A_NOTIFICATION,
  EXCEL_A_TRAINING,
  GET_ALL_SOCIALMIDIA,
  CREATE_A_SOCIALMIDIA,
  UPDATE_A_SOCIALMIDIA,
  DELETE_A_SOCIALMIDIA,
};
