import { Person } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
  Slide,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { green } from "@mui/material/colors";
import { useAppDispatch, useAppSelector } from "hooks/storeHook";
import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { USER_CREATE_PAYLOAD } from "store/reducers/usuarios/users.reducer";

// import { Container } from './styles';

const UsuariosPageEdit: React.FC = () => {
  const [animationState, setAnimationState] = React.useState(false);
  const dispatchAction = useAppDispatch();
  const Navigation = useNavigate();
  const { id } = useParams<{ id: string }>();
  const {
    loading: isLoading,
    users,
    success,
    msg,
    user,
  } = useAppSelector((state) => state.users);

  const { register, watch, handleSubmit, setValue, getValues } = useForm<{
    id: number;
    active: boolean;
    type: any;
    name: string;
    birthDate: Date | null;
    email: string;
    userName: string;
  }>({
    defaultValues: user,
  });

  const Submit = (data: any) => {
    dispatchAction(USER_CREATE_PAYLOAD({ ...data }));
    if (success !== undefined && success !== null && success === true) {
      Navigation("/operators/list");
    }
  };
  /*React.useEffect(() => {
    //console.log(users);
    users.map((item) => {
      if (item.id === Number(id)) {
        setValue("id", item.id || 0);
        setValue("active", item.active !== undefined ? item.active : false);
        setValue("type", item.type || "operator");
        setValue("name", item.name || "");
        setValue("email", item.email || "");
        setValue("userName", item.userName || "");

        if (item.birthDate && item.birthDate !== null) {
          //console.log(item.birthDate);
          setValue("birthDate", item.birthDate);
        }
      }
    });
  }, []);*/

  React.useEffect(() => {
    //console.log(msg);
    if (success !== undefined && success !== null && success === true) {
      Navigation("/operators/list");
    }
  }, [success, msg]);

  React.useEffect(() => {
    setAnimationState(true);
    return () => setAnimationState(false);
  }, []);

  return (
    <>
      <Box
        sx={{
          width: "95%",
          margin: "0 auto",
          height: "100vh",
          mt: { xs: 5, sm: 0 },
        }}
      >
        <Slide direction="up" in={animationState} mountOnEnter unmountOnExit>
          <Box
            component={Paper}
            py={5}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              flexBasis: 1,
              alignItems: "center",
              width: "80%",
              margin: "0 auto",
            }}
          >
            <Box
              py={2}
              sx={{ display: "flex", flexDirection: "column" }}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Person sx={{ fontSize: "3.5em", color: green[500] }} />
              <Typography variant="h5">Editar Operador</Typography>
            </Box>
            <Stack
              component={"form"}
              noValidate
              direction={"column"}
              spacing={5}
              sx={{ width: "80%" }}
              onSubmit={handleSubmit(Submit)}
            >
              <Stack direction={"row"} spacing={2}>
                <TextField
                  type="text"
                  label="Nome"
                  {...register("name")}
                  fullWidth
                  inputProps={{
                    maxLength: 100,
                  }}
                />
                <FormControl component="fieldset" variant="standard">
                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        {...register("active")}
                        defaultChecked={getValues("active")}
                      />
                    }
                    label="Ativo"
                    sx={{
                      ".MuiFormControlLabel-label": {
                        color: green["500"],
                      },
                    }}
                  />
                </FormControl>
              </Stack>
              <Stack direction={"row"} spacing={2}>
                <TextField
                  type="date"
                  InputLabelProps={{ shrink: true, required: false }}
                  label="Data de Nascimento"
                  {...register("birthDate", { valueAsDate: true })}
                  //defaultValue={birthDateWatch}
                  //defaultValue={getDefaultDate()}
                  fullWidth
                />
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Tipo de usuario
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Tipo de usuario"
                    {...register("type")}
                    defaultValue={getValues("type")}
                  >
                    <MenuItem value={"admin"}>Administrador</MenuItem>
                    {
                      //<MenuItem value={"operator"}>Operador</MenuItem>
                    }
                    <MenuItem value={"app"}>Aplicativo</MenuItem>
                  </Select>
                </FormControl>
              </Stack>
              <TextField
                type="email"
                label="E-mail"
                fullWidth
                {...register("email")}
                inputProps={{
                  maxLength: 244,
                }}
              />
              <Stack direction={"row"} spacing={2}>
                <TextField
                  type="text"
                  label="Usuario"
                  {...register("userName")}
                  fullWidth
                  inputProps={{
                    maxLength: 244,
                  }}
                />
              </Stack>
              <Button
                color="primary"
                type="submit"
                variant="contained"
                fullWidth
              >
                Salvar
              </Button>
            </Stack>
          </Box>
        </Slide>
      </Box>
      <Backdrop
        sx={{
          bgColor: "#3333",
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default UsuariosPageEdit;
