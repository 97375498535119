import React from "react";
import { ThemeProvider } from "@mui/system";
import { PersistGate } from "redux-persist/integration/react";
import MainRoutes from "routes/main.routes";
import lightTheme from "theme/light.theme";
import { Provider } from "react-redux";
import { store, psersistedStore } from "store/store.app";

function App() {
  return (
    <ThemeProvider theme={lightTheme}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={psersistedStore}>
          <MainRoutes />
        </PersistGate>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
