import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { green } from "@mui/material/colors";
import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { styled } from "@mui/system";
import { ErrorMessage } from "@hookform/error-message";

const Input = styled("input")({
  display: "none",
});

const TraningFormFirst: React.FC = () => {
  const {
    register,
    setValue,
    getValues,
    formState: { errors },
    watch,
  } = useFormContext();
  const idWatch = watch("id");
  var [imageUrl, setImageUrl] = useState("");

  React.useEffect(() => {
    const image = getValues("imageUrl");
    if (image !== undefined && image !== null && image !== "") {
      imageUrl = image;
      setImageUrl(imageUrl);
    }
  }, []);

  const onImageChange = (e: any) => {
    imageUrl = "";
    const file = e.target.files[0];
    try {
      imageUrl = URL.createObjectURL(file);
      setValue("image", e.target.files);
    } catch (error) {
      console.log(error);
    }
    setImageUrl(imageUrl);
  };

  const getDefaultDate = (nameValue: string) => {
    const value = getValues(nameValue);
    if (value === undefined || value === null || value === "") {
      const now = new Date();
      const result = now.toISOString().substring(0, 10);
      //console.log(result);
      return result;
    }
    const result = value.lenght > 9 ? value.substring(0, 10) : value;
    //console.log(result);
    return result;
  };

  return (
    <Stack direction="column" spacing={2} p={5} mb={5}>
      <Stack direction="row" spacing={2}>
        <Box
          sx={{ display: "flex", flexDirection: "column", width: "50%" }}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <FormControl component="fieldset" variant="standard">
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  {...register("active")}
                  defaultChecked={true}
                />
              }
              label="Ativo"
              sx={{
                ".MuiFormControlLabel-label": {
                  color: green["500"],
                },
              }}
            />
          </FormControl>
        </Box>
        {!idWatch && (<Box
          sx={{ display: "flex", flexDirection: "column", width: "100%" }}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <FormControl component="fieldset" variant="standard">
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  {...register("notificate")}
                  defaultChecked={true}
                />
              }
              label="Notificar usuários"
              sx={{
                ".MuiFormControlLabel-label": {
                  color: green["500"],
                },
              }}
            />
          </FormControl>
        </Box>)}
      </Stack>
      <TextField
        type="text"
        label="Nome do evento"
        {...register("name", { required: "Nome obrigatório" })}
        fullWidth
        variant="outlined"
        required={true}
        inputProps={{
          maxLength: 100,
        }}
      />
      <ErrorMessage
        errors={errors}
        name="name"
        render={({ message }) => <p style={{ color: "red" }}>{message}</p>}
      />
      <Stack direction="row" spacing={3}>
        <Box sx={{ width: "40%" }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Tipo do evento
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Tipo do evento"
              {...register("type")}
              defaultValue={"treinamento"}
              required={true}
            >
              <MenuItem value={"treinamento"}>Treinamento</MenuItem>
              <MenuItem value={"congresso"}>Congresso</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ width: "30%", margin: " 0 auto " }}>
          <TextField
            //name="startDate"
            type="date"
            InputLabelProps={{ shrink: true, required: true }}
            label="Data de início"
            {...register("startDate", { valueAsDate: true })}
            defaultValue={getDefaultDate("startDate")}
            variant="outlined"
            fullWidth
          />
        </Box>
        <Box sx={{ width: "30%", margin: " 0 auto " }}>
          <TextField
            //name="endDate"
            type="date"
            InputLabelProps={{ shrink: true, required: true }}
            label="Data do final"
            {...register("endDate", { valueAsDate: true })}
            defaultValue={getDefaultDate("endDate")}
            variant="outlined"
            fullWidth
          />
        </Box>
      </Stack>
      <TextField
        type="text"
        multiline
        rows={4}
        label="Descrição do evento"
        {...register("description", { required: "Descrição obrigatória" })}
        fullWidth
        variant="outlined"
      />
      <ErrorMessage
        errors={errors}
        name="description"
        render={({ message }) => <p style={{ color: "red" }}>{message}</p>}
      />
      <TextField
        type="text"
        multiline
        rows={6}
        label="Resposta do e-mail para o inscrito"
        {...register("emailBody", { required: "Resposta obrigatória" })}
        fullWidth
        variant="outlined"
        defaultValue={`<b>Shalom, seja bem vindo(a)!</b><br><br> 
Você estará recebendo essa mensagem automática, confirmando sua inscrição no próximo treinamento do Ramo Estendido.<br><br>
Qualquer informação necessária, entre em contato em nosso WhatsApp <b>19 9 9694-5816</b> ou no E-mail <b>contato@ramoestendido.com.br</b><br><br>
Obrigado.`}
      />
      <ErrorMessage
        errors={errors}
        name="emailBody"
        render={({ message }) => <p style={{ color: "red" }}>{message}</p>}
      />
      {imageUrl !== "" ? (
        <img src={imageUrl} alt={""} style={{ maxWidth: "150px" }} />
      ) : (
        <div></div>
      )}
      <Stack direction="row" alignItems="center" spacing={2}>
        <label htmlFor="contained-button-file">
          <Input
            accept="image/*"
            id="contained-button-file"
            type="file"
            {...register("image")}
            onChange={onImageChange}
          />
          <Button variant="contained" component="span">
            Selecionar imagem
          </Button>
        </label>
      </Stack>
    </Stack>
  );
};

export default TraningFormFirst;
