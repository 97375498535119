import { createAction, createReducer, AnyAction } from "@reduxjs/toolkit";
import { ResDash } from "services/api";

const initialState: {
  dashboard: ResDash | null;
  loading: boolean;
  error: boolean;
  success: boolean;
  msg: string;
} = {
  dashboard: null,
  loading: false,
  error: false,
  success: false,
  msg: "",
};
export const DASH_ASYNC =
  createAction("DASH_ASYNC");
export const DASH_ASYNC_SUCCESS = createAction<{
  dashboard: ResDash;
}>("DASH_ASYNC_SUCCESS");
export const DASH_ASYNC_ERROR =
  createAction<{ msg: string }>("USER_CREATE_ERROR");
export const DASH_CHANGED_STATE = createAction<any>("DASH_CHANGED_STATE");

const dashReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(
      DASH_ASYNC_SUCCESS.type,
      (state, action: AnyAction) => {
        state.dashboard = action.payload;
        state.success = true;
        state.error = false;
      }
    )
    .addCase(DASH_CHANGED_STATE.type, (state, action: AnyAction) => {
      return {
        ...state,
        ...action.payload,
      };
    })
    .addCase(DASH_ASYNC_ERROR.type, (state, action: AnyAction) => {
      state.error = false;
      state.success = false;
      state.msg = action.payload;
    });
});

export default dashReducer;
