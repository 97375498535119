import React from "react";
import {
  AppBar,
  Divider,
  Drawer,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { Outlet, useNavigate } from "react-router-dom";
//import { Menu } from "@mui/icons-material";
import ListDrawer from "./partials/list.drawer";
import TopNavigation from "./partials/nav.tab";
import { useAppDispatch, useAppSelector } from "hooks/storeHook";
import {
  VALIDATE_SEND_QUEST,
  AUTH_CHANGED_STATE,
} from "store/reducers/auth/auth.reducer";
//import { USER_ASYNC_ALL } from "store/reducers/usuarios/users.reducer";
//import { FEED_ASYNC_ALL } from "store/reducers/feeds/feeds.reducer";

const drawerWidth = 240;
const MainLayoute: React.FC = (props: any) => {
  const appState = useAppSelector((state) => state);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  React.useEffect(() => {
    if (!appState.auth.auth?.user) {
      navigate("/");
    } else {
      //dispatch(USER_ASYNC_ALL({page: 0}));
      //dispatch(FEED_ASYNC_ALL());
      //console.log(appState.auth.auth);
      dispatch(
        VALIDATE_SEND_QUEST({
          token: appState.auth.auth?.token,
          id: Number(appState.auth.auth?.user.id),
        })
      );
    }
  }, [appState.auth]);
  if (appState.auth.isAuth) {
    return (
      <Box
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: { xs: "column", sm: "row" },
        }}
      >
        <AppBar
          position="fixed"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
            display: { sm: "block", xs: "none" },
          }}
        >
          <Toolbar variant="dense">
            <Stack direction="row" spacing={2} sx={{ width: "100%" }}>
              <Typography variant="h6">{`Bem Vindo ${appState.auth.auth?.user.name}`}</Typography>
              <Typography
                variant="h6"
                marginRight={"10"}
                style={{ cursor: "pointer", textDecoration: "underline" }}
                onClick={() => {
                  dispatch(
                    AUTH_CHANGED_STATE({
                      auth: null,
                      isAuth: false,
                    })
                  );
                  navigate("/");
                }}
              >
                Sair
              </Typography>
            </Stack>
          </Toolbar>
        </AppBar>
        <TopNavigation />
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, xs: "100%" }}
          aria-label="Menu"
        >
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
            open
          >
            <Box
              component="img"
              alt="Ramo esteendido"
              src="http://www.ramoestendido.com.br/wp-content/uploads/2014/02/logo.png"
              sx={{
                margin: "0 auto",
                width: "100%",
                height: "auto",
              }}
            />
            <Divider />
            <ListDrawer />
          </Drawer>
        </Box>
        <Stack
          component="main"
          direction="column"
          sx={{
            flexGrow: 1,
            py: { sm: 10, xs: 5 },
            px: { sm: 0, xs: 5 },
            width: { sm: `calc(100% - ${drawerWidth}px)`, xs: "100%" },
            flexDirection: "column",
          }}
        >
          <Outlet />
        </Stack>
      </Box>
    );
  } else {
    return (
      <Box
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: { xs: "column", sm: "row" },
        }}
      >
        <Outlet />
      </Box>
    );
  }
};

export default MainLayoute;
