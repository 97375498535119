import { createAction, createReducer, AnyAction } from "@reduxjs/toolkit";
import { ResAuth } from "services/api";

const initialState: {
  auth: ResAuth | null;
  isAuth: boolean;
  loading: boolean;
  error: boolean;
  msgError: string | null;
} = {
  isAuth: false,
  auth: null,
  loading: false,
  error: false,
  msgError: null,
};
export const AUTH_SEND_QUEST =
  createAction<{ email: string; password: string }>("AUTH_SEND_QUEST");
export const VALIDATE_SEND_QUEST =
  createAction<{ token: string; id: number }>("VALIDATE_SEND_QUEST");
export const RESPONSE_SUCCESS_AUTHENTICATION = createAction<{
  user: ResAuth;
}>("RESPONSE_SUCCESS_AUTHENTICATION");
export const AUTH_CHANGED_STATE = createAction<any>("AUTH_CHANGED_STATE");
const authReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(
      RESPONSE_SUCCESS_AUTHENTICATION.type,
      (state, action: AnyAction) => {
        // eslint-disable-next-line no-param-reassign
        state.auth = action.payload.user;
        // eslint-disable-next-line no-param-reassign
        state.isAuth = true;
      }
    )
    .addCase(AUTH_CHANGED_STATE.type, (state, action: AnyAction) => {
      // eslint-disable-next-line no-param-reassign
      return {
        ...state,
        ...action.payload,
      };
    });
});

export default authReducer;
